import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormArray } from '@angular/forms';

@Component({
  selector: 'app-agenda-marca',
  templateUrl: './agenda-marca.component.html',
  styleUrls: ['./agenda-marca.component.css']
})
export class AgendaMarcaComponent implements OnChanges {


  @Input() agendaResultados:any = {};
  @Input() numeroProcesso: string = '';
  constructor() { }

  ngOnInit(): void {
  }


  ngOnChanges(changes: SimpleChanges): void {
    // ;
      console.log(changes);
  }
}
