import { Component, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { ID_CLIENTE } from 'app/constantes/variaveis';
import { EventEmitter } from '@angular/core';
import { EmpresaProvider } from 'services/empresa.provider';

@Component({
  selector: 'app-partes-processo-desenho',
  templateUrl: './partes-processo-desenho.component.html',
  styleUrls: ['./partes-processo-desenho.component.css']
})
export class PartesProcessoDesenhoComponent implements OnChanges {

  @Output() partesProcessoPatente = new EventEmitter();

  @Input() partesProcessoIn:any = {}
  @Input() processoExisteRpi:boolean = false;
  @Input() processoTerceirado:boolean = false;

  listaInventores:FormGroup = new FormGroup({
    invetor:new FormControl(null)
  })

  partesProcesso:FormGroup = new FormGroup({
    titular:new FormControl(null),
    inventor:new FormControl(null),
    inventores:new FormArray([]),
    procurador:new FormControl(null),
    correspondente:new FormControl(null),
    interessado:new FormControl(null),

  })

  correspondentes = [];
  interassados = [];
  inventores = [];

  constructor(private service:EmpresaProvider) {}

  ngOnChanges(changes: SimpleChanges): void {

    this.service.getTiposEmpresasByCliente(parseInt(ID_CLIENTE),"correspondente").subscribe(res =>{
      this.correspondentes = res;
      this.partesProcesso.controls['correspondente'].setValue(this.partesProcessoIn.correspondente);
    })

    this.service.getTiposEmpresasByCliente(parseInt(ID_CLIENTE),"interessado").subscribe(res =>{   
      this.interassados = res;
    })

    this.service.getTiposEmpresasByCliente(parseInt(ID_CLIENTE),"inventor").subscribe(res =>{   
      this.inventores = res;
      
    })

    this.novoInvertor();


    if(changes.partesProcessoIn !== undefined && !changes.partesProcessoIn.firstChange)
    {     
      
      this.partesProcesso = new FormGroup({
        titular:new FormControl(null),
        inventor:new FormControl(null),
        inventores:new FormArray([]),
        procurador:new FormControl(null),
        correspondente:new FormControl(null),
        interessado:new FormControl(null),
    
      })

      setTimeout(() => {
        this.partesProcesso.controls['correspondente'].setValue(this.partesProcessoIn.correspondente);
      }, 500);


      for (let index = 0; index < this.partesProcessoIn.inventores.length; index++) {        
        this.novoInvertor();
      }
     
      this.partesProcesso.setValue(this.partesProcessoIn);      
      this.eventPartesProcessoPatente();
    }
    
  }

  novoInvertor(){
    this.getFormInvetor().push(new FormGroup({
      invetor:new FormControl(null)
    }));
    this.eventPartesProcessoPatente();
  }

  removeInventor(indice:number)
  {
    if(indice > 0)
    {
      this.getFormInvetor().removeAt(indice);
      this.preencheInventor();
      this.eventPartesProcessoPatente();
    }    
  }

  preencheInventor(){
    let inventores = "";
   this.getFormInvetor().value.forEach(element => {
     for (let index = 0; index < this.inventores.length; index++) {
        if(this.inventores[index].id == element.invetor)
        {
          inventores += this.inventores[index].nome + "; ";
        }       
     }
   });

   this.partesProcesso.controls['inventor'].setValue(inventores);
   this.eventPartesProcessoPatente();
    
  }

  eventPartesProcessoPatente() {
    this.partesProcessoPatente.emit(this.partesProcesso);
  }

  getFormInvetor() : FormArray{
    return this.partesProcesso.controls['inventores'] as FormArray;
  }

}
