import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ID_CLIENTE, ID_USUARIO } from 'app/constantes/variaveis';
import { Usuario } from 'models/Usuario';
import { agendaPrazosProvider } from 'services/agendaPrazos.provider';
import { EmpresaProvider } from 'services/empresa.provider';
import { ParametrosPrazosProvider } from 'services/parametros-prazos.provider';
import { ParametrosCadastraisProvider } from 'services/parametrosCadastrais.provider';
import { patenteProvider } from 'services/patente.provider';
import { usuarioProvider } from 'services/usuario.provider';

@Component({
  selector: 'app-agenda-prazo-desenho',
  templateUrl: './agenda-prazo-desenho.component.html',
  styleUrls: ['./agenda-prazo-desenho.component.css']
})
export class AgendaPrazoDesenhoComponent implements OnChanges {

  parametros: any = [];
  sigla: any = [];
  empresa: any = [];
  paisesUnionista: any = [];
  usuarios: Array<Usuario> = [];
  prazos:FormArray = new FormArray([]);

  editando:boolean = false;
  indiceEdit:number = 0;
  erro:string = ""

  @Input() agendaResultados:Array<{
    idTipoPrazo:'',
    assunto:'',
    tipoPrazo:'',
    fatoGerador:'',
    dtPrazo:'',
    responsavel:''
  }> = null

  @Input() numeroProcesso:string = null;

  cadastro: FormGroup = new FormGroup({
    id: new FormControl(null),
    dtPrazo: new FormControl(null, [Validators.required]),
    processo: new FormControl(null, [Validators.required]),
    assunto: new FormControl(null),
    assuntoLivre: new FormControl(null),
    pasta: new FormControl(null),
    despacho: new FormControl(null),
    responsavel: new FormControl(null),
    fatoGerador: new FormControl(null),
    tipoPrazo: new FormControl(null),
    idProcesso: new FormControl(null),
    ocorrenciaPrazo: new FormControl(null),
    idCliente: new FormControl(ID_CLIENTE),
    obeservacao: new FormControl(null),
    prazoBaixado: new FormControl(null),
    usuarioBaixa: new FormControl(null),
    dataBaixa: new FormControl(null),
    usuarioNome: new FormControl(null),
  });

  constructor(
    private service_parametros: ParametrosCadastraisProvider,
    private service_usuario: usuarioProvider,
    private service_prazos: agendaPrazosProvider,
    private service_Empresa: EmpresaProvider,
    private service: patenteProvider,
    private service_parametrosPrazos: ParametrosPrazosProvider
  ) { }
  ngOnChanges(changes: SimpleChanges): void {

    this.service_usuario.getSigla(ID_USUARIO).subscribe(res =>{
      this.sigla = res.sigla
    })

    this.service_parametros.getParametrosAgendaPrazo().subscribe(res => {
      this.parametros = res;
    });

    this.service_usuario.getUsuarios().subscribe(res => {
      this.usuarios = res;
    })

    let idCliente: number = parseInt(localStorage.getItem("idCliente"));
    this.service_Empresa.getEmpresaTipo(idCliente, 1).subscribe(res => {
      this.empresa = res
    });

    this.service_parametros.getPaisesCadastrados().subscribe(res => {
      this.paisesUnionista = res;
    })

  
  }


  busca = new FormGroup({
    "idUsuario": new FormControl(ID_USUARIO),
    "correspondente": new FormControl(0),
    "pasta": new FormControl(''),
    "titular": new FormControl(''),
    "assunto": new FormControl(''),
    "idResponsavel": new FormControl(0),
    "paisDeposito": new FormControl(0),
    "processo": new FormControl(''),
    "rpi": new FormControl(''),
    "tipoDeProcesso": new FormControl(''),
    "despacho": new FormControl(''),
    "situacaoPrazo": new FormControl(''),
    "dataDepositoIni": new FormControl(null),
    "dataDepositoFim": new FormControl(null),
    "tipoPrazo": new FormControl(''),
    "dataConcessaoIni": new FormControl(null),
    "dataConcessaoFim": new FormControl(null),
    "fatoGerador": new FormControl(''),
    "idCliente":new FormControl(ID_CLIENTE)
  })

  novoPrazo = new FormGroup({
    "id": new FormControl(0),
    "processo": new FormControl('', Validators.required),
    "pasta": new FormControl(''), 
    "idTipoPrazo": new FormControl(null), 
    "idAssuntoPadrao": new FormControl(null), 
    "idFatoGerador": new FormControl(null), 
    "ocorrencia": new FormControl(null),
    "assuntoLivre": new FormControl(null), 
    "dataPrazo1": new FormControl(null),
    "observacao": new FormControl(null),
    "idResponsavel": new FormControl(null),
    "prazoBaixado": new FormControl(false),
    "idUsuarioPrazoBaixado": new FormControl(null),
    "idCliente":new FormControl(ID_CLIENTE)
  })

  editaPrazo = new FormGroup({
    "id": new FormControl(0),
    "processo": new FormControl('', Validators.required),
    "pasta": new FormControl(''),
    "idTipoPrazo": new FormControl(null),
    "idAssuntoPadrao": new FormControl(null),
    "idFatoGerador": new FormControl(null),
    "ocorrencia": new FormControl(null),
    "assuntoLivre": new FormControl(null),
    "dataPrazo1": new FormControl(null),
    "observacao": new FormControl(null),
    "idResponsavel": new FormControl(null),
    "idUsuarioPrazoBaixado": new FormControl(ID_USUARIO),
    "prazoBaixado": new FormControl(false),
    "idCliente":new FormControl(ID_CLIENTE)
  })


  ativarCampos() {
    this.cadastro.controls["assunto"].enable();
    this.cadastro.controls["dtPrazo"].enable();
    this.cadastro.controls["tipoPrazo"].enable();
    this.cadastro.controls["processo"].enable();
    this.cadastro.controls["pasta"].enable();
    this.cadastro.controls["assuntoLivre"].enable();
    this.cadastro.controls["ocorrenciaPrazo"].enable();
    this.cadastro.controls["fatoGerador"].enable();
    this.cadastro.controls["responsavel"].enable();
  }

  onSubmit() {
    if (this.cadastro.valid) {
      this.ativarCampos();
      console.log(this.cadastro.value);
       ;
      this.service.cadastraPrazoProcesso(this.cadastro.value).subscribe(
        (res) => {
          if (res.cadastrado) {
            alert(res.mensagem);
             ;
            let arrayAgenda:Array<any> = this.agendaResultados;
            if(arrayAgenda.filter(s => s.idTipoPrazo == res.objeto.idTipoPrazo)?.length)
            {
              arrayAgenda.map((obj,index) =>{
                // ;
                if(obj.idTipoPrazo == res.objeto.idTipoPrazo)
                {
                   ;
                  this.agendaResultados[index] = res.objeto
                }
              })
            }else{
              this.agendaResultados.push(res.objeto)
            }
            this.closeModal();
            this.limpar();
          } else {
            this.erro = res.mensagem;
          }
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }

  closeModal(){
    document.getElementById("agenda").click();
  }

  limpar() {
    this.editando = false;
    this.cadastro = new FormGroup({
      id: new FormControl(null),
      dtPrazo: new FormControl(null, [Validators.required]),
      processo: new FormControl(null, [Validators.required]),
      assunto: new FormControl(null),
      assuntoLivre: new FormControl(null),
      pasta: new FormControl(null),
      despacho: new FormControl(null),
      responsavel: new FormControl(null),
      fatoGerador: new FormControl(null),
      tipoPrazo: new FormControl(null),
      idProcesso: new FormControl(null),
      ocorrenciaPrazo: new FormControl(1,[Validators.required]),
      idCliente: new FormControl(ID_CLIENTE),
      obeservacao: new FormControl(null),
      prazoBaixado: new FormControl(null),
      usuarioBaixa: new FormControl(null),
      dataBaixa: new FormControl(null),
      usuarioNome: new FormControl(null),
    });
  }

  editarPrazo(idPrazo: string) {
    this.service.buscaPrazosPorId(idPrazo).subscribe(
      (res) => {
         ;
        console.log(res);
        this.limpar();
        this.cadastro.setValue(res);
        if (res.fatoGerador == 1) {
          this.desativarCampos();
        }

        this.editando = true;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  rebootModal(){
    this.limpar();
    this.cadastro.controls.processo.setValue(this.numeroProcesso);
  }

  baixarPrazo() {
    if (this.cadastro.value.prazoBaixado) {
      console.log(
        new Date().getFullYear() +
          "-" +
          (new Date().getMonth() + 1) +
          "-" +
          new Date().getDate()
      );
      this.cadastro.controls["usuarioBaixa"].setValue(ID_USUARIO);
      this.cadastro.controls["dataBaixa"].setValue(
        new Date().getFullYear() +
          "-" +
          (new Date().getMonth() + 1) +
          "-" +
          new Date().getDate()
      );
    }
  }
  
  limparPrazo(){
     ;
    this.editando = false;
    this.cadastro = new FormGroup({
      "id": new FormControl(0),
      "processo": new FormControl(this.numeroProcesso, Validators.required),
      "pasta": new FormControl(''),
      "idTipoPrazo": new FormControl(null),
      "idAssuntoPadrao": new FormControl(null),
      "idFatoGerador": new FormControl(null),
      "ocorrencia": new FormControl(null),
      "assuntoLivre": new FormControl(null),
      "dataPrazo1": new FormControl(null),
      "observacao": new FormControl(null),
      "idResponsavel": new FormControl(null),
      "prazoBaixado": new FormControl(false),
      "idUsuarioPrazoBaixado": new FormControl(null),
      "idCliente":new FormControl(ID_CLIENTE)
    })
  }

  removerFormAnuidade(indice:number)
  {
    this.prazos.removeAt(indice);
    console.log('clicado!!' + indice)
    //this.anuidadesForm.removeAt(indice);
  }
  cadastrarPrazo(): void {
  if(!this.editando)
    this.prazos.push(this.novoPrazo)
  else{
    this.editando = false;
     this.prazos.controls[this.indiceEdit].setValue(this.novoPrazo.value)
     this.indiceEdit = 0;
    }
   console.log(this.prazos)

   this.limparPrazo();
  }

  desativarCampos() {
     ;
    this.cadastro.controls["assunto"].disable();
    this.cadastro.controls["dtPrazo"].disable();
    this.cadastro.controls["tipoPrazo"].disable();
    this.cadastro.controls["processo"].disable();
    this.cadastro.controls["pasta"].disable();
    this.cadastro.controls["assuntoLivre"].disable();
    this.cadastro.controls["ocorrenciaPrazo"].disable();
    this.cadastro.controls["fatoGerador"].disable();
    this.cadastro.controls["responsavel"].disable();
  }

}
