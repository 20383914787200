import {Observable, throwError, observable} from 'rxjs';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import {catchError, retry, take, map} from 'rxjs/operators';
import { login } from '../const';
import { Injectable } from '@angular/core';
import { Clientes } from 'models/Clientes';
import { Login } from 'models/Login';
import { LoginAdm } from 'models/LoginAdm';
import { Router } from '@angular/router';
import { LoginCliente } from 'models/LoginCliente';

@Injectable()
export class loginProvider{
    constructor(private http : HttpClient,
                private route: Router){ }

   isLoggedAdm():string{
      return localStorage.getItem('loginAdmin');
   }

   isLoggedCliente():boolean{
      if(localStorage.getItem('id') != null || localStorage.getItem('idCliente') != null)
      {
         return true;
      }
      else
         return false;
   }

   logout():void{
      localStorage.removeItem('loginAdmin');
      this.route.navigate(['loginAdm'])
   }

   logoutSistema():void{
      localStorage.removeItem('id');
      localStorage.removeItem('idCliente');
      localStorage.removeItem('adm');
      localStorage.removeItem('idUsuario');
      localStorage.removeItem('loginAdmin');
      this.route.navigate(['login'])
      
      window.location.href = location.href;
   }

   confereToken(obj): Observable<any>{
      return this.http.post<any>(login+'/confereToken',obj).pipe(
         map(res =>{           
            return res;
            //console.log("Cliente Cadastrado com sucesso");
         }),catchError(error =>{
            return throwError(error);
         })
      );
   }


   login(lg:LoginAdm): Observable<any>{
        return this.http.post<any>(login+'/loginAdm',lg).pipe(
           map(res =>{
              if(res.logadoAdm){
                 localStorage.setItem('loginAdmin','okok');
                 this.route.navigate(['/cadastro-cliente']);
              }
              return res;
              //console.log("Cliente Cadastrado com sucesso");
           }),catchError(error =>{
              return throwError(error);
           })
        );
     }

     loginCliente(lg:LoginCliente): Observable<any>{
      return this.http.post<any>(login+'/loginClientes',lg).pipe(
         map(res =>{
            if(res.mensagem == "OK"){
               
            //SEPARANDO ADMINISTRADOR DE USUARIO NORMAL
            if(res.administrador)
            {
               localStorage.setItem('adm', res.administrador); 
               setTimeout(() => {
                      this.route.navigate(['sistema/cadastro-usuario'])
               }, 3000);
           
            }else{
               localStorage.setItem('idUsuario', res.idUsuario); 
               setTimeout(() => {
                  this.route.navigate(['sistema/home'])
           }, 3000);
               
            }
         }
            return res;
            //console.log("Cliente Cadastrado com sucesso");
         }),catchError(error =>{
            return throwError(error);
         })
      );
   }
}