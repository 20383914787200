
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BaseService } from "./BaseService";

@Injectable()
export class FileService extends BaseService {
    constructor(http: HttpClient) {
        super(http, 'receivefile')
    }
}