import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-datas-processo-desenho',
  templateUrl: './datas-processo-desenho.component.html',
  styleUrls: ['./datas-processo-desenho.component.css']
})
export class DatasProcessoDesenhoComponent implements OnChanges {

  @Output() formDatasProcesso = new EventEmitter();
  @Input()  datas:any = {};
  @Input()  processoExisteRpi:boolean = false;

  formDatas:FormGroup = new FormGroup({
    dataConcessao : new FormControl(null),
    dataDeposito : new FormControl(null),
    dataVigencia : new FormControl(null),
    //dataLimiteExameTecnico : new FormControl(null),
    //dataPublicacao : new FormControl(null),
  });
  
  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if(!changes.datas.firstChange)
    {
      this.formDatas.setValue(this.datas)
      this.mudanca()
    }           
  }

  teste(){
    console.log("AQUI");
    
  }


  mudanca(){
    this.formDatasProcesso.emit(this.formDatas);
  }

}
