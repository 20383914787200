import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { LoginCliente } from 'models/LoginCliente';
import { loginProvider } from 'services/login.provider';
import { ROUTES, Router } from '@angular/router';
import { environment } from 'environments/environment';


@Component({
  selector: 'app-login-sistema',
  templateUrl: './login-sistema.component.html',
  styleUrls: ['./login-sistema.component.css']
})
export class LoginSistemaComponent implements OnInit {

  @ViewChild('recaptcha', {static: true }) recaptchaElement: ElementRef;

  constructor(private service_: loginProvider,private router: Router) { }

  ngOnInit(): void {
    this.addRecaptchaScript();
  }

  aguarde:boolean = false;
  token:string = null;

  confereEnter($event,usuario:string, senha:string){
    if($event.key == "Enter")
    {
      this.login_(usuario,senha);
    }
  }


  renderReCaptch() {
    window['grecaptcha'].render(this.recaptchaElement.nativeElement, {
      'sitekey' : '6LfMEnMaAAAAADBGHzp--7kMyMsR1ATerHtseeeY',
      'callback': (response) => {
          console.log(response);
          this.token = response;
      }
    });
  }

  addRecaptchaScript() {
 
    window['grecaptchaCallback'] = () => {
      this.renderReCaptch();
    }
   
    (function(d, s, id, obj){
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { return;}
      js = d.createElement(s); js.id = id;
      js.src = "https://www.google.com/recaptcha/api.js?onload=grecaptchaCallback&amp;render=explicit";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'recaptcha-jssdk', this));
   
  }

  login_(usuario:string, senha:string){

    let t:HTMLElement = document.querySelector("#erro");
    if(this.token != null)
    {
      var source = this;
      let lg : LoginCliente = new LoginCliente(usuario,senha);
      this.service_.loginCliente(lg).subscribe(res =>{        
        if(res.mensagem != "OK"){
          this.aguarde = false
          this.addRecaptchaScript();        
          t.setAttribute("style","display:block;")
          t.innerHTML = res.mensagem;
        }else{
          let obj = {
            token:this.token
          }

          let id = res.id;
          let idCliente = res.idCliente;
          this.service_.confereToken(obj).subscribe(res => {
            if(res.conferido)
            {           
              this.aguarde = true;
              localStorage.setItem('id', id);
              localStorage.setItem('idCliente', idCliente);              
              this.router.routeReuseStrategy.shouldReuseRoute = () => false;
              this.router.onSameUrlNavigation = 'reload';         
              this.router.navigate(['agenda-prazos'])
            }else{
              t.setAttribute("style","display:block;")
              t.innerHTML = "Ocorreu um erro ao validar o ReCaptcha, tente novamente.";
            }
          })          
        }
      },err =>{
        t.setAttribute("style","display:block;")
        t.innerHTML = "Ocorreu um erro ao efetuar o login";
      })
      
    }else{
      t.setAttribute("style","display:block;")
      t.innerHTML = "Você precisa validar o ReCaptcha";
    }
  
  }

  // login_(usuario:string, senha:string){

  
  //         let lg : LoginCliente = new LoginCliente(usuario,senha);
  //         this.aguarde = true;
  //         this.service_.loginCliente(lg).subscribe(res =>{
  //           if(res.mensagem != "OK"){
  //             let id = res.id;
  //             let idCliente = res.idCliente;
  //             this.aguarde = false
  //             let t:HTMLElement = document.querySelector("#erro");
  //             t.setAttribute("style","display:block;")
  //             t.innerHTML = res.mensagem;
  //             this.aguarde = true;

  //           }else{
  //             let id = res.id;
  //             let idCliente = res.idCliente;
  //             localStorage.setItem('id', id);
  //             localStorage.setItem('idCliente', idCliente);              
  //             this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  //             this.router.onSameUrlNavigation = 'reload';         
  //             this.router.navigate(['agenda-prazos'])
  //           }
  //         },err =>{
      
  //         })
       
  
  // }
}
