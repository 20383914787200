import { Observable, of } from "rxjs";
//import { environment } from "";
import { HttpClient } from "@angular/common/http";
import { tap, catchError } from 'rxjs/operators';

export class BaseService {

    constructor(public http: HttpClient, public api) {
    }

    /** POST: add a new file to the server */
    sendFile(file: any, rpi, cliente, data, lida): Observable<any> {
        let url = "";

        if (lida) {
           //url = "http://localhost:22867/api/ReceiveFile/li/" + rpi + "/" + cliente + "/" + data;
           url = "https://hcip.com.br/projetos/api/ReceiveFile/li/" + rpi + "/" + cliente + "/" + data;
        }
        else {
           //url = "http://localhost:22867/api/ReceiveFile/" + rpi + "/" + cliente + "/" + data;
            url = "https://hcip.com.br/projetos/api/ReceiveFile/" + rpi + "/" + cliente + "/" + data;
        }


        //const url = "http://191.234.181.228/projetos/api/ReceiveFile/"+rpi+"/"+cliente+"/"+data;
        return this.http.post(url, file, { responseType: 'text' })
            .pipe(tap(), catchError(this.handleError<any>('base service add')));
    }

    sendFileFigurativa(file: any, rpi, cliente, data): Observable<any> {
        let url = "";

       
        //url = "http://localhost:22867/api/ReceiveFile/figurativa/" + rpi + "/" + cliente + "/" + data;
        url = "https://hcip.com.br/projetos/api/ReceiveFile/figurativa/" + rpi + "/" + cliente + "/" + data;
        //url = "https://hcip.com.br/projetos/api/ReceiveFile/" + rpi + "/" + cliente + "/" + data;
        


        //const url = "http://191.234.181.228/projetos/api/ReceiveFile/"+rpi+"/"+cliente+"/"+data;
        return this.http.post(url, file, { responseType: 'text' })
            .pipe(tap(), catchError(this.handleError<any>('base service add')));
    }

    sendFilePatente(file: any, correspondente, cliente): Observable<any> {
        let url = "";

        ;
        //url = "http://localhost:22867/api/ReceiveFile/patente/" + correspondente + "/" + cliente;
        // alert(cliente + 'cliente...')
        // alert(correspondente + 'correspondente')

        //*SUSPEITO QUE O ERRO ESTEJA NA URL* 
        url = "https://hcip.com.br/projetos/api/ReceiveFile/patente/" + correspondente + "/" + cliente;
        


        //const url = "http://191.234.181.228/projetos/api/ReceiveFile/"+rpi+"/"+cliente+"/"+data;
        return this.http.post(url, file, { responseType: 'text' })
            .pipe(tap(), catchError(this.handleError<any>('base service add')));
    }

    sendFileDesenhoIndustrial(file: any, correspondente, cliente): Observable<any>{
        let url = "";

        //url = "http://localhost:22867/api/ReceiveFile/desenhoIndustrial/" + correspondente + "/" + cliente;
        // alert(cliente + 'cliente...')
        // alert(correspondente + 'correspondente')

        //*SUSPEITO QUE O ERRO ESTEJA NA URL* 
        url = "https://hcip.com.br/projetos/api/ReceiveFile/patente/" + correspondente + "/" + cliente;
        


        //const url = "http://191.234.181.228/projetos/api/ReceiveFile/"+rpi+"/"+cliente+"/"+data;
        return this.http.post(url, file, { responseType: 'text' })
            .pipe(tap(), catchError(this.handleError<any>('base service add')));
    }

    sendFilePesquisa(file: any): Observable<any> {

        let url = "";

        ;
        //url = "http://localhost:22867/api/ReceiveFile/pesquisaPatente/";
        
        url = "https://hcip.com.br/projetos/api/ReceiveFile/pesquisaPatente/";
        


        //const url = "http://191.234.181.228/projetos/api/ReceiveFile/"+rpi+"/"+cliente+"/"+data;
        return this.http.post(url, file, { responseType: 'text' })
            .pipe(tap(), catchError(this.handleError<any>('base service add')));
    }

    senFilePesquisaDesenho(file: any): Observable<any> {
        let url = "";

        ;
        //url = "http://localhost:22867/api/ReceiveFile/pesquisaDesenhoIndustrial/";


        url = "https://hcip.com.br/projetos/api/ReceiveFile/pesquisaDesenhoIndustrial/";
        


        //const url = "http://191.234.181.228/projetos/api/ReceiveFile/"+rpi+"/"+cliente+"/"+data;
        return this.http.post(url, file, { responseType: 'text' })
            .pipe(tap(), catchError(this.handleError<any>('base service add')));
    }

    protected log(message: string) {
        message = message;
    }

    sendFileCartas(file: any, cliente, idioma, despacho): Observable<any> {
        let url = "";
         //url = "http://localhost:22867/api/ReceiveFile/cartas/" + cliente+"/"+idioma+"/"+despacho;
        url = "https://hcip.com.br/projetos/api/ReceiveFile/cartas/" + cliente+"/"+idioma+"/"+despacho;        
        //const url = "http://191.234.181.228/projetos/api/ReceiveFile/"+rpi+"/"+cliente+"/"+data;
        return this.http.post(url, file, { responseType: 'text' })
            .pipe(tap(), catchError(this.handleError<any>('base service add')));
    }
    

    /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    protected handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {

            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // TODO: better job of transforming error for user consumption
            this.log(`${operation} failed: ${error.message}`);

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }

    service(): string {
        // var url = environment.endpoint.endsWith("/") ?
        //     environment.endpoint.substring(0, environment.endpoint.length - 1) : environment.endpoint;

        return "";
    }
}