import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ID_CLIENTE } from 'app/constantes/variaveis';
import { EmpresaProvider } from 'services/empresa.provider';
import { FileService } from 'services/FileService.provider';
import { patenteProvider } from 'services/patente.provider';
import { usuarioProvider } from 'services/usuario.provider';

@Component({
  selector: 'app-importacao-patente',
  templateUrl: './importacao-patente.component.html',
  styleUrls: ['./importacao-patente.component.css']
})
export class ImportacaoPatenteComponent implements OnInit {

  sendRes: any;
  form: FormGroup;
  file: any;
  clientes: any = [];
  aguarda: boolean = false;
  correspondentes:any = [];
  urlDownload:string = "https://hcip.com.br/projetos/ModelosDownload/ModeloListagemPatenteProcesso.xlsx";

  tipoAnuidade:FormGroup = new FormGroup({
    tipo: new FormControl(""),
  })

  importacaoProcesso: FormGroup = new FormGroup({
    'correspondente': new FormControl(null, Validators.required),
    'cliente': new FormControl(ID_CLIENTE, Validators.required)
  })

  constructor(private service:patenteProvider,private serviceEmpresa:EmpresaProvider, private serviceFile: FileService) { }

  ngOnInit(): void {
    this.form = new FormGroup({
      anexos: new FormControl()
    });
    this.serviceEmpresa.getTiposEmpresasByCliente(parseInt(ID_CLIENTE),"correspondente").subscribe(res =>{
      this.correspondentes = res;
    });
  }

  limpar(){
    // this.importacaoProcesso = new FormGroup({
    //   'correspondente': new FormControl(null, Validators.required),
    //   'cliente': new FormControl(ID_CLIENTE, Validators.required)
    // });
    this.tipoAnuidade = new FormGroup({
      tipo: new FormControl(""),
    });
    this.ngOnInit();
    this.sendResToChild(null);
  }

  get formCampos(): any {
    return this.importacaoProcesso.controls;
  }

  onFileChange(event) {
    if (event.target.files && event.target.files.length) {
      this.file = event.target.files[0];
    };
  }
  sendResToChild(res: any){
    this.sendRes = res;
  }

  onSubmit(){
    this.aguarda = true;
     ;
    if (this.tipoAnuidade.controls["tipo"].value == "Patente") {
       ;
      if (this.importacaoProcesso.valid) {
        let file = new FormData();
        file.append("file", this.file);
        file.append("id", "222");
        console.log('passei aqui');

        this.serviceFile.sendFilePatente(file, this.importacaoProcesso.value.correspondente, this.importacaoProcesso.value.cliente).subscribe(res =>{
           ;
          if(res.length != 2){
            alert(res);
          } else{
            alert("Processo(s) Cadastrado(s)")
          }
          this.aguarda = false;
          
          let jsonRes = JSON.parse(res);
          if(jsonRes.length > 0){
            this.sendResToChild(jsonRes);
          }

        }, err => {
          this.aguarda = false;
          alert("Ocorreu um erro ao importar o arquivo, tente novamente");
        });
      }
    }
    else if(this.tipoAnuidade.controls["tipo"].value == "Desenho Industrial"){
       ;
      if (this.importacaoProcesso.valid) {
        let file = new FormData();
        file.append("file", this.file);
        file.append("id", "222");
        console.log('passei aqui');

        //Criar um método diferente para envio de desenho
        this.serviceFile.sendFileDesenhoIndustrial(file, this.importacaoProcesso.value.correspondente, this.importacaoProcesso.value.cliente).subscribe(res => {
          alert(res);
          console.log(res);

          let jsonRes = JSON.parse(res);
          if(jsonRes.length > 0){
            this.sendResToChild(jsonRes);
          }
          this.aguarda = false;
        }, err => {
          this.aguarda = false;
          alert("Ocorreu um erro ao importar o arquivo, tente novamente");
          console.log(err);
        });
      }
    }

  }

}
