import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-publicacoes-desenho',
  templateUrl: './publicacoes-desenho.component.html',
  styleUrls: ['./publicacoes-desenho.component.css']
})
export class PublicacoesDesenhoComponent implements OnChanges {

  @Input() publicacoes: Array<{
    rpi: "",
    data: "",
    despacho: "",
    descricaoDespacho: "",
    complementoDespacho: ""
  }>;
  constructor() {}

  formPublicacoes: FormArray = new FormArray([]);

  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes.publicacoes);
      this.publicacoes.map((publi, i) => {
        this.adicionaProcessoPatente(
          publi.rpi,
          publi.data,
          publi.despacho,
          publi.descricaoDespacho,
          publi.complementoDespacho
        );
      });
     
    
  }

  adicionaProcessoPatente(
    rpi,
    data,
    despacho,
    descricaoDespacho,
    complementoDespacho
  ) {
    this.formPublicacoes.push(
      new FormGroup({
        "rpi": new FormControl(rpi),
        "data": new FormControl(data),
        "despacho": new FormControl(despacho),
        "descricaoDespacho": new FormControl(descricaoDespacho),
        "complementoDespacho":  new FormControl(complementoDespacho)
      })
    );
    
  }

}
