import { Observable, throwError, observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { catchError, retry, take, map } from 'rxjs/operators';
import { guiaRecolhimento } from '../const';
import { Injectable } from '@angular/core';

@Injectable()
export class guiaRecolhimentoProvider {
   headersConfig = { 'Content-Type': 'application/json', 'Accept': 'application/json' };
   idCliente = localStorage.getItem('idUsuario');

   constructor(private http: HttpClient) { }

   getMarcaByPage(dtParams): Observable<any> {
      dtParams["userId"] = this.idCliente;
      return this.http.post<any>(guiaRecolhimento + '/GuiaRecolhimento/ReturnDataByPage', dtParams, { headers: this.headersConfig })
         .pipe(
            map(res => {
               return res;
            }), catchError(error => {
               return throwError(error);
            })
         );
   }

   getPatenteByPage(dtParams): Observable<any> {
      dtParams["userId"] = this.idCliente;
      return this.http.post<any>(guiaRecolhimento + '/GuiaRecolhimentoPatentes/ReturnDataByPage', dtParams, { headers: this.headersConfig })
         .pipe(
            map(res => {
               return res;
            }), catchError(error => {
               return throwError(error);
            })
         );
   }

   getDesenhoIndustrialByPage(dtParams): Observable<any> {
      dtParams["userId"] = this.idCliente;
      return this.http.post<any>(guiaRecolhimento + '/GuiaRecolhimentoDesenhoIndustrial/ReturnDataByPage', dtParams, { headers: this.headersConfig })
         .pipe(
            map(res => {
               return res;
            }), catchError(error => {
               return throwError(error);
            })
         );
   }

   getRecibosByPage(dtParams): Observable<any> {
      dtParams["userId"] = this.idCliente;
      return this.http.post<any>(guiaRecolhimento + '/Recibo/ReturnDataByPage', dtParams, { headers: this.headersConfig })
         .pipe(
            map(res => {
               return res;
            }), catchError(error => {
               return throwError(error);
            })
         );
   }

   importarGuiaRec(file: FormData, fileType: string) {
      console.warn('service called');
      file.append("UserId", this.idCliente);
      file.append("FileType", fileType);
      //[Consumes("multipart/form-data")]
      var headersConfig = { 'Content-Type': 'application/x-www-form-urlencoded' };

      return this.http.post<any>(guiaRecolhimento + '/GuiaRecolhimento/Import', file)
         .pipe(
            map(res => {
               console.log("Importação Realizada com sucesso");
               return res;
            }), catchError(error => {
               return throwError(error);
            })
         );
   }

   importarGuiaRecPatente(file: FormData, fileType: string) {
      console.warn('service called');
      file.append("UserId", this.idCliente);
      file.append("FileType", fileType);


      return this.http.post<any>(guiaRecolhimento + '/GuiaRecolhimentoPatentes/Import', file)
         .pipe(
            map(res => {
               console.log("Importação Realizada com sucesso");
               return res;
            }), catchError(error => {
               return throwError(error);
            })
         );
   }

   importarGuiaRecDesenhoIndustrial(file: FormData) {
      console.warn('service called');
      file.append("UserId", this.idCliente);

      return this.http.post<any>(guiaRecolhimento + '/GuiaRecolhimentoDesenhoIndustrial/Import', file)
         .pipe(
            map(res => {
               console.log("Importação Realizada com sucesso");
               return res;
            }), catchError(error => {
               return throwError(error);
            })
         );
   }

   importarRecibo(file: FormData) {
      console.warn('service called');
      file.append("UserId", this.idCliente);
      //[Consumes("multipart/form-data")]
      var headersConfig = { 'Content-Type': 'application/x-www-form-urlencoded' };

      return this.http.post<any>(guiaRecolhimento + '/Recibo/Import', file)
         .pipe(
            map(res => {
               console.log("Importação Realizada com sucesso");
               return res;
            }), catchError(error => {
               return throwError(error);
            })
         );
   }

   getProgress() {
      return this.http.get(guiaRecolhimento + '/GuiaRecolhimento/Progress', { headers: this.headersConfig })
         .pipe(
            map(res => {
               return res;
            }), catchError(error => {
               return throwError(error);
            })
         );
   }

   getProgressPatente() {
      return this.http.get(guiaRecolhimento + '/GuiaRecolhimentoPatentes/Progress', { headers: this.headersConfig })
         .pipe(
            map(res => {
               return res;
            }), catchError(error => {
               return throwError(error);
            })
         );
   }

   getProgressDesenhoIndustrial() {
      return this.http.get(guiaRecolhimento + '/GuiaRecolhimentoDesenhoIndustrial/Progress', { headers: this.headersConfig })
         .pipe(
            map(res => {
               return res;
            }), catchError(error => {
               return throwError(error);
            })
         );
   }

   getProgressRecibo() {
      return this.http.get(guiaRecolhimento + '/Recibo/Progress', { headers: this.headersConfig })
         .pipe(
            map(res => {
               return res;
            }), catchError(error => {
               return throwError(error);
            })
         );
   }


   removeAllMarcas() {
      var dtParams = { userId: this.idCliente };
      return this.http.post<any>(guiaRecolhimento + '/GuiaRecolhimento/RemoveAll', dtParams, { headers: this.headersConfig })
         .pipe(
            map(res => {
               return res;
            }), catchError(error => {
               return throwError(error);
            })
         );
   }

   removeAllPatentes() {
      var dtParams = { userId: this.idCliente };
      return this.http.post<any>(guiaRecolhimento + '/GuiaRecolhimentoPatentes/RemoveAll', dtParams, { headers: this.headersConfig })
         .pipe(
            map(res => {
               return res;
            }), catchError(error => {
               return throwError(error);
            })
         );
   }

   removeAllDesenhoIndustrial() {
      var dtParams = { userId: this.idCliente };
      return this.http.post<any>(guiaRecolhimento + '/GuiaRecolhimentoDesenhoIndustrial/RemoveAll', dtParams, { headers: this.headersConfig })
         .pipe(
            map(res => {
               return res;
            }), catchError(error => {
               return throwError(error);
            })
         );
   }

   removeAllRecibos() {
      var dtParams = { userId: this.idCliente };
      return this.http.post<any>(guiaRecolhimento + '/Recibo/RemoveAll', dtParams, { headers: this.headersConfig })
         .pipe(
            map(res => {
               return res;
            }), catchError(error => {
               return throwError(error);
            })
         );
   }

   getConfiguracao() {
      var obj
      return this.http.get(guiaRecolhimento + '/Configuracao/Index?userId=' + this.idCliente, { headers: this.headersConfig })
         .pipe(
            map(res => {
               return res;
            }), catchError(error => {
               return throwError(error);
            })
         );
   }

   createConfiguracao(configuracao) {
      configuracao.idUser = this.idCliente;
      return this.http.post(guiaRecolhimento + '/Configuracao/Create', configuracao, { headers: this.headersConfig })
         .pipe(
            map(res => {
               return res;
            }), catchError(error => {
               return throwError(error);
            })
         );
   }

   getFolders(module, type, isLog, isRecibo) {
      var obj
      return this.http.get<string[]>(guiaRecolhimento + '/Configuracao/GetFolders?userId=' + this.idCliente + '&module=' + module + "&type=" + type + "&isLog=" + isLog + "&isRecibo=" + isRecibo, { headers: this.headersConfig })
         .pipe(
            map(res => {
               return res;
            }), catchError(error => {
               return throwError(error);
            })
         );
   }

   getFilesFromFolder(module, type, isLog, folderName, isRecibo) {
      return this.http.get<string[]>(guiaRecolhimento + '/Configuracao/GetFilesFromFolder?userId=' + this.idCliente + '&module=' + module + "&type=" + type + "&isLog=" + isLog + "&folderName=" + folderName + "&isRecibo=" + isRecibo, { headers: this.headersConfig })
         .pipe(
            map(res => {
               return res;
            }), catchError(error => {
               return throwError(error);
            })
         );
   }

   downloadFiles(module, type, isLog, folderName, isRecibo) {
      return guiaRecolhimento + '/Configuracao/DownloadFiles?userId=' + this.idCliente + '&module=' + module + "&type=" + type + "&isLog=" + isLog + "&folderName=" + folderName + "&isRecibo=" + isRecibo;
   }

   removeFolders(module, type, isLog, isRecibo) {
      var obj
      return this.http.get<string[]>(guiaRecolhimento + '/Configuracao/RemoveFolders?userId=' + this.idCliente + '&module=' + module + "&type=" + type + "&isLog=" + isLog + "&isRecibo=" + isRecibo, { headers: this.headersConfig })
         .pipe(
            map(res => {
               return res;
            }), catchError(error => {
               return throwError(error);
            })
         );
   }
}