import { Component, Input, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-resultados-pesquisa',
  templateUrl: './resultados-pesquisa.component.html',
  styleUrls: ['./resultados-pesquisa.component.css']
})
export class ResultadosPesquisaComponent implements OnInit {

  @Input() resultadosPesquisa :any= [];
  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
     ;
    console.log(changes);
    //this.resultadosImportacao = changes.processo;
}
}
