import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-detalhes-processo-desenho',
  templateUrl: './detalhes-processo-desenho.component.html',
  styleUrls: ['./detalhes-processo-desenho.component.css']
})
export class DetalhesProcessoDesenhoComponent implements OnChanges {


  @Input() processoExisteRpi:boolean = false;
  @Input() processo = {};
  @Output() processoDetalhesOut = new EventEmitter()

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if(!changes.processo.firstChange)
      {
        this.detalhes.setValue(this.processo);                     
        this.eventChange(); 
      } 
  }

  detalhes: FormGroup = new FormGroup({
    processo: new FormControl("", Validators.required),
    //processoAnterior: new FormControl(null),
    processoDividido: new FormControl(null),
    classificacao: new FormControl(null),
    //classificacaoIpc: new FormControl(null),
    //classificacaoCpc: new FormControl(null),
    titulo: new FormControl(null),
    //resumo: new FormControl(null),
    situacaoProcesso: new FormControl(null),
    imagemProcesso: new FormControl(null),
  });

  eventChange()
  {
    this.processoDetalhesOut.emit(this.detalhes);
  }
}
