import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { clienteProvider } from 'services/cliente.provider';
import { colidenciaProvider } from 'services/colidencia.provider';

@Component({
  selector: 'app-excluir-colidencia',
  templateUrl: './excluir-colidencia.component.html',
  styleUrls: ['./excluir-colidencia.component.css']
})
export class ExcluirColidenciaComponent implements OnInit {

  rpis:Array<number> = [];
  clientes:Array<any> = [];
  aguarda:boolean = false;
  aguardaExclusao:boolean = false;

  form:FormGroup = new FormGroup({
    'idCliente':new FormControl(null, Validators.required),
    'rpi':new FormControl(null, Validators.required)
  });

  constructor(private serviceCliente:clienteProvider, private serviceColidencia: colidenciaProvider) { }

  ngOnInit(): void {
    this.serviceCliente.getClientes().subscribe(res => {
      this.clientes = res;
    })
  }

  getRpis(idCliente) {
    this.aguarda = true;
    this.serviceColidencia.getRpis(idCliente).subscribe(res => {
      this.rpis = res.rpis;
      this.aguarda = false;
    });
  }

  excluirRPI(){
    console.log(this.form.value);
    this.aguardaExclusao = true;
    this.serviceColidencia.excluirRpi(this.form.value).subscribe(res =>
     {
       this.aguardaExclusao = false;
       alert(res.mensagem);
       this.getRpis(this.form.value.idCliente)
     },err =>{
      this.aguardaExclusao = false;
     } );
  }
}
