import {Observable, throwError, observable} from 'rxjs';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import {catchError, retry, take, map} from 'rxjs/operators';
import { cliente } from '../const';
import { Injectable } from '@angular/core';
import { Clientes } from 'models/Clientes';

@Injectable()
export class clienteProvider{
    constructor(private http : HttpClient){ }

    public saveCliente(clientes_:Clientes): Observable<any>{
        return this.http.post<any>(cliente+'saveCliente',clientes_).pipe(
           map(res =>{
              console.log(res);
              console.log("Cliente Cadastrado com sucesso");
           }),catchError(error =>{
              return throwError(error);
           })
        );
     }

     public uploadCliene(clientes_:Clientes):Observable<any>{
      return this.http.put<any>(cliente+'updateCliente',clientes_).pipe(
         map(res =>{
            console.log("Cliente Cadastrado com sucesso");
            return res;
         }),catchError(error =>{
            return throwError(error);
         })
      );
     }

     public getClientesById(id:number):Observable<any>{
        return this.http.get<any>(cliente+id).pipe(
           map(res =>{
              return res;
           }),catchError(err =>{
              return throwError(err);
           })
        )
     }

     public getClientes():Observable<any>{
        return this.http.get<any>(cliente).pipe(
           map(res =>{
            return res;
           }),catchError(err =>{
            return throwError(err);
           }))
     }

   public uploadFoto(imagem_:FormData): Observable<any>{
      let headers = new Headers();
      let options = {
         headers: new HttpHeaders({
            'enctype': 'multipart/form-data'
         })}
      headers.append('Content-Type', 'multipart/form-data');
      return this.http.post<any>(cliente+"saveLogo",imagem_,options).pipe(
         map(res =>{
            console.log(res);
            console.log("Imagem Inserida");
         }),catchError(error =>{
            return throwError(error);
         })
      );
   }  
}