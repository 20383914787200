import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { PaisesUnionista } from 'models/PaisesUnionista';
import { ParametrosCadastraisProvider } from 'services/parametrosCadastrais.provider';

@Component({
  selector: 'app-prioridade-marca',
  templateUrl: './prioridade-marca.component.html',
  styleUrls: ['./prioridade-marca.component.css']
})
export class PrioridadeMarcaComponent implements OnInit {

  @Input() prioridades: any = {};
  @Input() processoExisteRpi: boolean = false;
  constructor(private service_parametros: ParametrosCadastraisProvider) { }

  ngOnInit(): void {
  }

  prioridadesForm: FormArray = new FormArray([]);
  paises_unionista: Array<PaisesUnionista> = [];
  editando: boolean = false;
  inidiceEdit: number = 0;
  @Output() prioridadeUnionistaProcesso = new EventEmitter();

  prioridade: FormGroup = new FormGroup({
    processo: new FormControl(""),
    data: new FormControl(""),
    pais: new FormControl(""),
    paisSigla: new FormControl(""),
  });

  ngOnChanges(changes: SimpleChanges): void {
    // ;
    this.service_parametros.getPaisesCadastrados().subscribe((res) => {
      this.paises_unionista = res;     
    });

    if (changes.prioridades.currentValue.length > 0) {
      console.log(changes.prioridades);
      
      this.prioridades.map((pr, i) => {     
        this.adicionaPrioridade(
          pr.processo,
          pr.data,
          pr.pais,         
        );
      });

      this.eventPassaPrioridade();
    }
  }

  eventPassaPrioridade()
  {
    this.prioridadeUnionistaProcesso.emit(this.prioridadesForm)
  }

  adicionaPrioridade(
    processo,
    data,
    pais,
  ): void {
    // ;
    this.prioridadesForm.push(
      new FormGroup({
        processo: new FormControl(processo),
        data: new FormControl(data),
        pais: new FormControl(pais),
      })
    )

    
    this.eventPassaPrioridade()
  }

  removerPrioridade(indice: number) {
    this.prioridadesForm.removeAt(indice);
    this.eventPassaPrioridade()
  }

  editarPrioridade(indice: number) {
    this.editando = true;
    this.inidiceEdit = indice;
    this.prioridade.setValue(this.prioridadesForm.value[indice]);
    this.eventPassaPrioridade()
  }

  limpaPrioridade() {
    this.prioridade =  new FormGroup({
      processo: new FormControl(""),
      data: new FormControl(""),
      pais: new FormControl(""),
    });
    this.eventPassaPrioridade()
  }

  adicionarPrioridade() {
    if (!this.editando) this.prioridadesForm.push(this.prioridade);
    else {
      this.prioridadesForm.controls[this.inidiceEdit].setValue(this.prioridade.value)
    }
    this.eventPassaPrioridade()
    this.editando = false;
    this.limpaPrioridade();
    //console.log(this.prioridades);
  }
}
