import { Observable, throwError, observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { catchError, retry, take, map } from 'rxjs/operators';
import { usuario, login } from '../const';
import { Injectable } from '@angular/core';
import { Clientes } from 'models/Clientes';
import { Login } from 'models/Login';
import { LoginAdm } from 'models/LoginAdm';
import { Router } from '@angular/router';
import { LoginCliente } from 'models/LoginCliente';
import { Usuario } from 'models/Usuario';
import { usuarioDTO } from 'models/UsuarioDTO';
import { UsuarioIndice } from 'models/UsuarioIndice';

@Injectable()
export class usuarioProvider {
   constructor(private http: HttpClient,
      private route: Router) { }

   public getUsuarios(): Observable<any> {
      return this.http.get<any>(usuario + '/indice/' + localStorage.getItem('idCliente')).pipe(
         map(res => {
            return res;
         }), catchError(err => {
            return throwError(err);
         }))
   }

   public getUsuariosAtivos(): Observable<any> {
      return this.http.get<any>(usuario + '/ativos/' + localStorage.getItem('idCliente')).pipe(
         map(res => {
            return res;
         }), catchError(err => {
            return throwError(err);
         }))
   }

   public getPermissaoCadastros(idUsuario: string): Observable<any> {
      return this.http.get<any>(usuario + '/permissao-cadastro/' + idUsuario).pipe(
         map(res => {
            return res;
         }), catchError(err => {
            return throwError(err);
         }))
   }
   public getModulosLiberados(idUsuario: string,idCliente): Observable<any> {
      return this.http.get<any>(usuario + '/modulos-usuarios/' + idCliente+"/"+idUsuario).pipe(
         map(res => {
            return res;
         }), catchError(err => {
            return throwError(err);
         }))
   }


   public getSigla(idUsuario: string): Observable<any> {
      return this.http.get<any>(usuario + '/sigla/' + idUsuario).pipe(
         map(res => {
            return res;
         }), catchError(err => {
            return throwError(err);
         }))
   }

   public getNome(idUsuario: string): Observable<any> {
      return this.http.get<any>(usuario + '/nome/' + idUsuario).pipe(
         map(res => {
            return res;
         }), catchError(err => {
            return throwError(err);
         }))
   }

   public getPermissaoAgendaDePrazos(idUsuario: string): Observable<any> {
      return this.http.get<any>(usuario + '/permissao-agenda/' + idUsuario).pipe(
         map(res => {
            return res;
         }), catchError(err => {
            return throwError(err);
         }))
   }

   public getUsuarioById(id: number): Observable<any> {
      return this.http.get<any>(usuario + "/" + id).pipe(
         map(res => {
            return res;
         }), catchError(err => {
            return throwError(err);
         })
      )
   }

   public getModulosUsuarios(): Observable<any> {
      return this.http.get<any>(usuario + "/modulosUsuario/" + localStorage.getItem('idCliente')).pipe(
         map(res => {
            return res;
         }), catchError(err => {
            return throwError(err);
         })
      )

   }

   public saveUsuario(obj: usuarioDTO): Observable<any> {
      return this.http.post<any>(usuario + '/saveUsuario', obj).pipe(
         map(res => {
            console.log(res);
            console.log("Usuário Cadastrado com sucesso");
         }), catchError(error => {
            return throwError(error);
         })
      );
   }

   public updateUsuario(obj: usuarioDTO): Observable<any> {
      return this.http.put<any>(usuario, obj).pipe(
         map(res => {
            console.log("Usuario Alterado com sucesso");
            return res;
         }), catchError(error => {
            return throwError(error);
         })
      );
   }
}