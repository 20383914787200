import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ID_CLIENTE, ID_USUARIO } from 'app/constantes/variaveis';
import { ParametrosCadastraisProvider } from 'services/parametrosCadastrais.provider';
import { patenteProvider } from 'services/patente.provider';
import { usuarioProvider } from 'services/usuario.provider';

@Component({
  selector: 'app-filtro-prazos',
  templateUrl: './filtro-prazos.component.html',
  styleUrls: ['./filtro-prazos.component.css']
})
export class FiltroPrazosComponent implements OnInit {

  constructor(private service_patente : patenteProvider) { }

  @Input() parametros: any = [];
  @Input() usuarios: any = [];

  @Output() resultadosBusca = new EventEmitter();
  @Output() prazoEscolhido = new EventEmitter();

  aguardaBusca: boolean = false;

  busca = new FormGroup({
    pasta: new FormControl(null),
    processo: new FormControl(null),
    descricao: new FormControl(null),
    rpi: new FormControl(null),
    despacho: new FormControl(null),
    idCliente: new FormControl(ID_CLIENTE),
    idUsuario: new FormControl(ID_USUARIO),
    ordenacao: new FormControl(null),
    dtDepositoInicio: new FormControl(null),
    dtDepositoFim: new FormControl(null),
    correspondente: new FormControl(null),
    titular: new FormControl(null),
    responsavel: new FormControl(null),
    tipoProcesso: new FormControl(null),
    situacaoPrazo:new FormControl(null),
    tipoPrazo: new FormControl(null),
    fatoGerador: new FormControl(null),
    tipo: new FormControl("patente")    
  })

  ngOnInit(): void {

  }

  limpar(){
    this.busca = new FormGroup({
      pasta: new FormControl(null),
      processo: new FormControl(null),
      descricao: new FormControl(null),
      rpi: new FormControl(null),
      despacho: new FormControl(null),
      idCliente: new FormControl(ID_CLIENTE),
      ordenacao: new FormControl(null),
      dtDepositoInicio: new FormControl(null),
      dtDepositoFim: new FormControl(null),
      correspondente: new FormControl(null),
      titular: new FormControl(null),
      responsavel: new FormControl(null),
      tipoProcesso: new FormControl(null),
      situacaoPrazo:new FormControl(null),
      tipoPrazo: new FormControl(null),
      fatoGerador: new FormControl(null),
      tipo: new FormControl("patente")       
    })
  }

  onSubmit(){
     ;
   if(this.busca.valid)
   {
     this.aguardaBusca = true;
     if(this.busca.value.tipo == 'patente'){
     this.service_patente.buscaPrazosProcesso(this.busca.value).subscribe(res =>{
        this.resultadosBusca.emit(res);       
        this.aguardaBusca = false;
     },err => {
        console.log(err);        
        this.aguardaBusca = false;
     })
    }
    else if(this.busca.value.tipo == 'desenho industrial'){
      this.service_patente.buscaPrazosProcessoDesenho(this.busca.value).subscribe(res =>{
        this.resultadosBusca.emit(res);
        this.aguardaBusca = false;
      }), err => {
        console.log(err);
        this.aguardaBusca = false;
      }
    }
   }
  }

  cadastraNovoPrazo()
  {
    console.log("aqui");
    
    this.prazoEscolhido.emit(null);
  }

}
