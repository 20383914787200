import { environment } from "environments/environment";

export const cliente = `${environment.BASE_API_URL}/cliente/`;
export const login = `${environment.BASE_API_URL}/login`;
export const usuario = `${environment.BASE_API_URL}/usuario`;
export const empresa = `${environment.BASE_API_URL}/empresa`;
export const parametroPrazos = `${environment.BASE_API_URL}/parametrosPrazos`;
export const parametrosCadastrais = `${environment.BASE_API_URL}/ParametroCadastrais`;
export const processosBusca = `${environment.BASE_API_URL}/BuscaProcesso`;
export const processos_api = `${environment.BASE_API_URL}/Processo`;
export const agendaPrazos = `${environment.BASE_API_URL}/agendaPrazos`;
export const parametroColidencia = `${environment.BASE_API_URL}/ParametroColidencia`;
export const colidencia = `${environment.BASE_API_URL}/ColidenciaCliente`;
export const relatorioColidencia = `${environment.BASE_API_URL}/RelatorioColidencia`;
export const cartasColidencia = `${environment.BASE_API_URL}/CartasColidencia`;
export const colidenciaFigurativa = `${environment.BASE_API_URL}/ColidenciaFigurativa/`;
export const parametrosCadastraisMarcas = `${environment.BASE_API_URL}/ParametroCadastrais/ParametrosMarcas`;
export const patente = `${environment.BASE_API_URL}/Patente`;
export const cobranca = `${environment.BASE_API_URL}/api/Cobranca`;

//MARCAS
export const marca = `${environment.BASE_MARCAS_API_ULR}`;
export const marcaClientes = `${environment.BASE_MARCAS_API_ULR}/ProcessoClientes`;

//GUIA
export const guiaRecolhimento = `${environment.BASE_GUIA_API}`

//Casa dos Dados
export const casaDosDados = `${environment.BASE_CASA_DADOS_API}`

//Registro BR
export const registroBr = `${environment.BASE_REGISTRO_BR_API}`