import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-historico-marca',
  templateUrl: './historico-marca.component.html',
  styleUrls: ['./historico-marca.component.css']
})
export class HistoricoMarcaComponent implements OnChanges {

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if(!changes.recebeHistorico.firstChange)
    {
      changes.recebeHistorico.currentValue.forEach(element => {
        this.historico.setValue(element);
        this.historicos.push(this.historico);
        this.eventEmitHistoricoProcesso();
      });
    }
  }

  editando:boolean = false;
  indiceEditando:number = 0;
  historico: FormGroup = new FormGroup({
    data: new FormControl(null),
    tipo: new FormControl(null),
    assunto:new FormControl(null),
  });

  historicos:FormArray = new FormArray([]);
  @Output() historicoProcesso = new EventEmitter();
  @Input() recebeHistorico = [];


  limpar(){
    this.historico = new FormGroup({
      data: new FormControl(null),
      tipo: new FormControl(null),
      assunto:new FormControl(null),
    });
  }

  editar(indice:number )
  {
    this.editando = true;
    this.indiceEditando = indice;
    this.historico.setValue(this.historicos.value[indice]);
  }

  remover(indice:number)
  {
    this.historicos.removeAt(indice);
  }

  eventEmitHistoricoProcesso(){
    this.historicoProcesso.emit(this.historicos);
  }

  salvar(){
    if(!this.editando)
    {
      this.historicos.push(this.historico);
    }else{
      this.historico.controls[this.indiceEditando] = this.historico
    }
    this.eventEmitHistoricoProcesso();
    console.log(this.historicos.value);
    
  }

}
