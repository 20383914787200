import { Component, OnInit } from '@angular/core';

declare const $: any;
declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}
export const ROUTES: RouteInfo[] = [
    //{ path: '/dashboard', title: 'Dashboard',  icon: 'dashboard', class: '' },
    { path: '/cadastro-cliente', title: 'Cadastro de Clientes',  icon: '', class: '' },
    // { path: '/parametros-cadastrais', title: 'Parâmetros Cadastrais',   icon: '', class: ''},
    { path: '/colidencia', title: 'Colidência',   icon: '', class: ''}, 
    { path: '/cartas-colidencia', title: 'Cartas Colidência',   icon: '', class: ''},
    { path: '/excluir-colidencia', title: 'Excluir Colidência',   icon: '', class: ''},
    { path: '/cobranca-cliente', title: 'Cobrança por Cliente', icon: '', class: ''}
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  menuItems: any[];

  constructor() { }

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
  }
  isMobileMenu() {
      if ($(window).width() > 991) {
          return false;
      }
      return true;
  };
}
