import { Component, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ID_CLIENTE } from 'app/constantes/variaveis';
import { EventEmitter } from 'events';
import { analytics } from 'googleapis/build/src/apis/analytics';
import { EmpresaProvider } from 'services/empresa.provider';
import { FileService } from 'services/FileService.provider';
import { patenteProvider } from 'services/patente.provider';

@Component({
  selector: 'app-pesquisa-processo-rpi',
  templateUrl: './pesquisa-processo-rpi.component.html',
  styleUrls: ['./pesquisa-processo-rpi.component.css']
})
export class PesquisaProcessoRpiComponent implements OnInit {

  sendRes: any;
  form: FormGroup;
  file: any;
  clientes: any = [];
  aguarda: boolean = false;
  correspondentes: any = [];
  urlDownload: string = "https://hcip.com.br/projetos/ModelosDownload/ModeloListagemPatentesDesenhoIndustrial.xlsx";
  urlDownloadRelatorio: string = "https://hcip.com.br/projetos/relatorios/";
  //urlDownloadRelatorio: string = "C:\HcOffice\Projetos\Relatorios/";

  nomeRelatorio: string = "";

  tipoAnuidade: FormGroup = new FormGroup({
    tipo: new FormControl(""),
  })

  constructor(private serviceFile: FileService) { }

  ngOnInit(): void {
    this.form = new FormGroup({
      anexos: new FormControl()
    });
  }

  limpar() {
    this.tipoAnuidade = new FormGroup({
      tipo: new FormControl("")
    });
    this.ngOnInit();
    this.sendResToChild(null);
  }

  onFileChange(event) {
    if (event.target.files && event.target.files.length) {
      this.file = event.target.files[0];
    };
  }

  BaixaRelatorio() {
    document.getElementById("relatorioLink").setAttribute("href", this.urlDownloadRelatorio + this.nomeRelatorio);
    document.getElementById("relatorioLink").click();
  }

  sendResToChild(res: any) {
    this.sendRes = res;
  }

  onSubmit() {
     ;
    this.aguarda = true;
    console.log(this.tipoAnuidade.controls["tipo"].value)
    if (this.tipoAnuidade.controls["tipo"].value == "Patentes") {

      if (this.file) {
        let file = new FormData();
        file.append("file", this.file);

        this.serviceFile.sendFilePesquisa(file)
          .subscribe(res => {
            console.log(res);
            this.aguarda = false;
            let objSON = JSON.parse(res);
             ;
            if (objSON.listaNaoExistentes.length > 0) {
              this.sendResToChild(objSON);
            }
            if (objSON.status) {
              this.nomeRelatorio = objSON.relatorio;
              this.BaixaRelatorio();
            }

          }, err => {
            this.aguarda = false;
            alert("Ocorreu um erro ao importar o arquivo, tente novamente")
            console.log(err);
          }
          )
      }
    }
    else if (this.tipoAnuidade.controls["tipo"].value == "Desenho Industrial") {
       ;
      console.log("Desenho Industrial");
      if (this.file) {
        let file = new FormData();
        file.append("file", this.file);

        this.serviceFile.senFilePesquisaDesenho(file)
          .subscribe(res => {
            console.log(res);
            this.aguarda = false;
            let objSON = JSON.parse(res);
             ;
            if (objSON.listaNaoExistentes.length > 0) {
              this.sendResToChild(objSON);
            }
            if (objSON.status) {
              this.nomeRelatorio = objSON.relatorio;
              this.BaixaRelatorio();
            }

          }, err => {
            this.aguarda = false;
            alert("Ocorreu um erro ao importar o arquivo, tente novamente")
            console.log(err);
          }
          )
      }
    }
  }
}
