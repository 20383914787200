import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-publicacoes-marca',
  templateUrl: './publicacoes-marca.component.html',
  styleUrls: ['./publicacoes-marca.component.css']
})
export class PublicacoesMarcaComponent implements OnChanges {

  @Input() publicacoes: Array<{
    rpi: "",
    dtPublicacao: "",
    despacho: "",
    descricaoDespacho: "",
    complementoDespacho: ""
  }>;
  //@Input() processoExisteRpi: boolean = false;
  constructor() { }

  formPublicacoes: FormArray = new FormArray([]);

  ngOnChanges(changes: SimpleChanges): void {
    // debugger;
    // console.log(changes.publicacoes);
      this.publicacoes.map((publi, i) => {
        this.adicionaProcessoMarca(
          publi.rpi,
          publi.dtPublicacao,
          publi.despacho,
          publi.descricaoDespacho,
          publi.complementoDespacho
        );
      });
     
  }

  adicionaProcessoMarca(
    rpi,
    dtPublicacao,
    despacho,
    descricaoDespacho,
    complementoDespacho
  ) {
    this.formPublicacoes.push(
      new FormGroup({
        "rpi": new FormControl(rpi),
        "dtPublicacao": new FormControl(dtPublicacao),
        "despacho": new FormControl(despacho),
        "descricaoDespacho": new FormControl(descricaoDespacho),
        "complementoDespacho":  new FormControl(complementoDespacho)
      })
    );
    
  }

}
