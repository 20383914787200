import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { PaisesUnionista } from 'models/PaisesUnionista';
import { Usuario } from 'models/Usuario';
import { EmpresaProvider } from 'services/empresa.provider';
import { ParametrosCadastraisProvider } from 'services/parametrosCadastrais.provider';
import { ProcessoProvider } from 'services/processo.provider';
import { usuarioProvider } from 'services/usuario.provider';

@Component({
  selector: 'app-dados-gerais-processo-desenho',
  templateUrl: './dados-gerais-processo-desenho.component.html',
  styleUrls: ['./dados-gerais-processo-desenho.component.css']
})
export class DadosGeraisProcessoDesenhoComponent implements OnChanges {

  paises_unionista: Array<PaisesUnionista> = [];
  usuarios: Array<Usuario> = [];
  tipo_prazo: any = [];
  tipos_peticao: any = [];
  tipos_classeNice = [];

  constructor(
    private service: ProcessoProvider,
    private service_usuario: usuarioProvider,
    private service_Empresa: EmpresaProvider,
    private service_parametros: ParametrosCadastraisProvider
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.service_parametros.getPaisesCadastrados().subscribe((res) => {
      this.paises_unionista = res;
    });

    this.service_usuario.getUsuariosAtivos().subscribe((res) => {
      this.usuarios = res;
    });

    this.service_parametros.getTipoPrazoPorCliente().subscribe((res) => {
      this.tipo_prazo = res;
    });

    this.service.buscaTipoPeticao().subscribe((res) => {
      this.tipos_peticao = res;
    });

    this.service.buscaClasseNice().subscribe((res) => {
      this.tipos_classeNice = res;
    });
    if (!changes.recebeDadosGerais.firstChange) {
      this.service_usuario.getUsuariosAtivos().subscribe((res) => {
        this.usuarios = res;
        setTimeout(() => {
          this.dadosGeraisForm.setValue(changes.recebeDadosGerais.currentValue);
          this.eventDadosGeraisProcesso();
        }, 150);
      });
    }
  }

  @Output() dadosGerais = new EventEmitter();
  @Input() recebeDadosGerais = new EventEmitter();

  dadosGeraisForm: FormGroup = new FormGroup({
    pastaReferencia: new FormControl(null),
    referenciaCliente: new FormControl(null),
    referenciaFinanceira: new FormControl(null),
    outros: new FormControl(null),
    patrono: new FormControl(null),
    responsavel: new FormControl(null),
    estagiario: new FormControl(null),
    associado: new FormControl(null),
    terceiros: new FormControl(false),
    altoRenome: new FormControl(false),
    tratamentoEspecial: new FormControl(false),
    abandonado: new FormControl(false),
    arquivoMorto: new FormControl(false),
    grupoDePatentes: new FormControl(false),
    descGrupoDePatentes: new FormControl(""),
    requerExame: new FormControl(false),
    sigilo: new FormControl(false),
    controlarRetribuicoes: new FormControl(false),
    reinvidicacoes: new FormControl(false),
    qtdReinvidicacoes: new FormControl(0),
    instrucoes: new FormControl(true),
    descricaoInstrucoes: new FormControl(null),
    faturamento: new FormControl(true),
    instrucoesFaturamento: new FormControl(null),
  });

  eventDadosGeraisProcesso() {
    this.dadosGerais.emit(this.dadosGeraisForm);
  }

}
