import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MarcaProvider } from 'services/marca.provider';

@Component({
  selector: 'app-detalhes-marca',
  templateUrl: './detalhes-marca.component.html',
  styleUrls: ['./detalhes-marca.component.css']
})
export class DetalhesMarcaComponent implements OnInit {

  @Input() processoExisteRpi: boolean = false;
  @Input() processo = {};
  @Output() processoDetalhesOut = new EventEmitter();

  constructor(private service: MarcaProvider) { }

  ngOnInit(){
  }

  ngOnChanges(changes: SimpleChanges): void {
    // ;
    if(!changes.processo.firstChange)
    {
      this.detalhes.setValue(this.processo);
      
      this.eventChange(); 
    }          
}

detalhes: FormGroup = new FormGroup({
  processo: new FormControl("", Validators.required),
  marca: new FormControl("", Validators.required),
  marcaExato: new FormControl(null),
  traducaoMarca: new FormControl(null),
  apresentacao: new FormControl(null),
  natureza: new FormControl(null),
  inscricaoInternacional: new FormControl(null),
  situacaoProcesso: new FormControl(null),
  apostila: new FormControl(null),
  classificacaoViena: new FormControl(null),
  imagemMarca: new FormControl(null),
  processoAnterior: new FormControl(null)
});

  eventChange()
  {
    // ;
    this.processoDetalhesOut.emit(this.detalhes);
  }
  

}
