import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { parseJSON } from 'jquery';
import { clienteProvider } from 'services/cliente.provider';
import { Cobranca } from 'services/cobranca.provider';
import { colidenciaProvider } from 'services/colidencia.provider';

@Component({
  selector: 'app-cobranca-cliente',
  templateUrl: './cobranca-cliente.component.html',
  styleUrls: ['./cobranca-cliente.component.css']
})
export class CobrancaClienteComponent implements OnInit {

  clientes:Array<any> = [];
  aguardaBusca:Boolean = false;
  contador:Number = 20;
  responseCobranca: any;
  
  cobrancas: FormGroup = new FormGroup({
    'idCliente': new FormControl(null),
    'data': new FormControl(null),
    'marca': new FormControl(false),
    'patente': new FormControl(false),
    'indicacoesGeografica': new FormControl(false),
    'judicial': new FormControl(false),
    'extrajudicial': new FormControl(false),
    'direitoAutoral': new FormControl(false),
    'contratos': new FormControl(false),
    'nomeDominio': new FormControl(false),
    'software': new FormControl(false)
  });

  // responseCobranca: FormGroup = new FormGroup({
  //   'cliente': new FormControl(null),
  //   'Periodo': new FormControl(null),
  //   'QtdMarcas': new FormControl(null),
  //   'QtdPatentes': new FormControl(null),
  //   'QtdContratos': new FormControl(null),
  //   'QtdIndGeograficas': new FormControl(null),
  //   'QtdJudicial': new FormControl(null),
  //   'QtdExtrajudicial': new FormControl(null),
  //   'QtdSoftware': new FormControl(null),
  //   'QtdDominio': new FormControl(null),
  //   'QtdDireitoAutoral': new FormControl(null),
  //   'ValorMarcas': new FormControl(null),
  //   'ValorPatentes': new FormControl(null),
  //   'ValorContratos': new FormControl(null),
  //   'ValorIndGeograficas': new FormControl(null),
  //   'ValorJudicial': new FormControl(null),
  //   'ValorExtrajudicial': new FormControl(null),
  //   'ValorSoftware': new FormControl(null),
  //   'ValorDominio': new FormControl(null),
  //   'ValorDireitoAutoral': new FormControl(null),
  //   'TipoMarcas': new FormControl(null),
  //   'TipoPatentes': new FormControl(null),
  //   'TipoContratos': new FormControl(null),
  //   'TipoIndGeograficas': new FormControl(null),
  //   'TipoJudicial': new FormControl(null),
  //   'TipoExtrajudicial': new FormControl(null),
  //   'TipoSoftware': new FormControl(null),
  //   'TipoDominio': new FormControl(null),
  //   'TipoDireitoAutoral': new FormControl(null),
  //   'PrecoTotalMarcas': new FormControl(null),
  //   'PrecoTotalPatentes': new FormControl(null),
  //   'PrecoTotalContratos': new FormControl(null),
  //   'PrecoTotalIndGeograficas': new FormControl(null),
  //   'PrecoTotalJudicial': new FormControl(null),
  //   'PrecoTotalExtrajudicial': new FormControl(null),
  //   'PrecoTotalSoftware': new FormControl(null),
  //   'PrecoTotalDominio': new FormControl(null),
  //   'PrecoTotalDireitoAutoral': new FormControl(null),
  //   'QtdTotal': new FormControl(null),
  //   'ValorTotal': new FormControl(null),
  // });

  constructor(private serviceCliente:clienteProvider, private cobrancaCliente:Cobranca, private serviceColidencia: colidenciaProvider) { }

  ngOnInit(): void {
    this.serviceCliente.getClientes().subscribe(res => {
       ;
      this.clientes = res;
      console.log(this.clientes)
    })
  }

  limpar(){
    this.cobrancas = new FormGroup({
    'idCliente': new FormControl(null),
    'data': new FormControl(null),
    'marca': new FormControl(false),
    'patente': new FormControl(false),
    'indicacoesGeografica': new FormControl(false),
    'judicial': new FormControl(false),
    'extrajudicial': new FormControl(false),
    'direitoAutoral': new FormControl(false),
    'contratos': new FormControl(false),
    'nomeDominio': new FormControl(false),
    'software': new FormControl(false)
    })
  }

  sendResToChild(res: any){
    this.responseCobranca = res;
  }

  onSubmit(){
     ;
    if(this.cobrancas.valid){
      let obj = this.cobrancas.value;
    this.cobrancaCliente.gerandoCobranca(obj).subscribe( res => {
      // ;
      //alert(res);
      this.responseCobranca = Array.of(res);
      //console.log(res);
    }, err => {
      console.log("erro (passou aqui): " + err);
    })
  }
  }
}
