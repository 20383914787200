import { Component, OnInit } from '@angular/core';
import { LoginCliente } from 'models/LoginCliente';
import { loginProvider } from 'services/login.provider';
import { Router } from '@angular/router';
import { LoginAdm } from 'models/LoginAdm';

@Component({
  selector: 'app-login-sistema',
  templateUrl: './login-adm.component.html',
  styleUrls: ['./login-adm.component.css']
})
export class LoginAdmComponent implements OnInit {

  constructor(private service_: loginProvider,private router: Router) { }

  ngOnInit(): void {
  }

  aguarde:boolean = false;

  login_(usuario:string, senha:string){
    let lg : LoginAdm = new LoginAdm(usuario,senha);
    this.aguarde = true;
    this.service_.login(lg).subscribe(res =>{
      if(!res.logadoAdm){
        this.aguarde = false
        let t:HTMLElement = document.querySelector("#erro");
        t.setAttribute("style","display:block;")
        t.innerHTML = "Usuário ou senha Inválidos";
      }
    },err =>{

    })
  }
}
