import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, Input, OnInit, SimpleChange, SimpleChanges, LOCALE_ID } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Cobranca } from 'services/cobranca.provider';

@Component({
  selector: 'app-resultados-cobranca',
  templateUrl: './resultados-cobranca.component.html',
  styleUrls: ['./resultados-cobranca.component.css']
})
export class ResultadosCobrancaComponent implements OnInit {

  @Input() resultadosCobranca: any = [];
  constructor(private service: Cobranca) { }
  cobrancaDTO: any = [];
  aguarda: boolean = false;
  //urlDownload: string = "https://hcip.com.br/projetos/ModelosDownload/ModeloListagemPatentesDesenhoIndustrial.xlsx";
  urlDownload: string = "C:\HcOffice\Projetos\ModelosRelatorios\Cobranca/";
  urlDownloadRelatorio: string = "https://hcip.com.br/projetos/relatorios/";
  //urlDownloadRelatorio: string = "C:\HcOffice\Projetos\Relatorios/";
  nomeRelatorio: string = "";
  relatorioForm:FormGroup = new FormGroup({
    'relatorio':new FormControl("",Validators.required)
  });
  
  ngOnInit(): void {
  }

  BaixarRelatorio(){
    document.getElementById("relatorioLink").setAttribute("href", this.urlDownloadRelatorio + this.nomeRelatorio);
    document.getElementById("relatorioLink").click();
  }

  responseCobranca: FormGroup = new FormGroup({
    'cliente': new FormControl(null),
    'Periodo': new FormControl(null),
    'QtdMarcas': new FormControl(null),
    'QtdPatentes': new FormControl(null),
    'QtdContratos': new FormControl(null),
    'QtdIndGeograficas': new FormControl(null),
    'QtdJudicial': new FormControl(null),
    'QtdExtrajudicial': new FormControl(null),
    'QtdSoftware': new FormControl(null),
    'QtdDominio': new FormControl(null),
    'QtdDireitoAutoral': new FormControl(null),
    'ValorMarcas': new FormControl(null),
    'ValorPatentes': new FormControl(null),
    'ValorContratos': new FormControl(null),
    'ValorIndGeograficas': new FormControl(null),
    'ValorJudicial': new FormControl(null),
    'ValorExtrajudicial': new FormControl(null),
    'ValorSoftware': new FormControl(null),
    'ValorDominio': new FormControl(null),
    'ValorDireitoAutoral': new FormControl(null),
    'TipoMarcas': new FormControl(null),
    'TipoPatentes': new FormControl(null),
    'TipoContratos': new FormControl(null),
    'TipoIndGeograficas': new FormControl(null),
    'TipoJudicial': new FormControl(null),
    'TipoExtrajudicial': new FormControl(null),
    'TipoSoftware': new FormControl(null),
    'TipoDominio': new FormControl(null),
    'TipoDireitoAutoral': new FormControl(null),
    'PrecoTotalMarcas': new FormControl(null),
    'PrecoTotalPatentes': new FormControl(null),
    'PrecoTotalContratos': new FormControl(null),
    'PrecoTotalIndGeograficas': new FormControl(null),
    'PrecoTotalJudicial': new FormControl(null),
    'PrecoTotalExtrajudicial': new FormControl(null),
    'PrecoTotalSoftware': new FormControl(null),
    'PrecoTotalDominio': new FormControl(null),
    'PrecoTotalDireitoAutoral': new FormControl(null),
    'QtdTotal': new FormControl(null),
    'ValorTotal': new FormControl(null),
  });
  gerarRelatorio(){
    this.aguarda = true;
    // ;
    this.ngOnChanges;
    this.service.gerandoWordCobranca(this.cobrancaDTO).subscribe(res =>{
       ;
      //console.log(res.value)
      //alert(res.gerando);
      console.log(res);
      let teste = res.relatorio;
      //let objSON = JSON.parse(res);
      if(res.status){
        this.nomeRelatorio = res.relatorio;
        this.BaixarRelatorio();
      }
      this.aguarda = false;
    }, err =>{
      console.log(err)
      this.aguarda = false;
    })
    
  }

  ngOnChanges(changes: SimpleChanges){
    this.cobrancaDTO = this.resultadosCobranca[0];
  }
}
