import {Observable, throwError, observable} from 'rxjs';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import {catchError, retry, take, map} from 'rxjs/operators';
import {  agendaPrazos } from '../const';
import { Injectable } from '@angular/core';

@Injectable()
export class agendaPrazosProvider{
    constructor(private http : HttpClient){ }

    public cadastraPrazos(obj:any):Observable<any>
    {
        return this.http.post<any>(agendaPrazos,obj).pipe(
            map(res =>{
               return res;              
            }),catchError(error =>{
               return throwError(error);
            })
         );
         
    }

    public alteraPrazos(obj:any):Observable<any>
    {
        return this.http.put<any>(agendaPrazos,obj).pipe(
            map(res =>{
               return res;              
            }),catchError(error =>{
               return throwError(error);
            })
         );
         
    }

    public buscaPrazos(obj:any):Observable<any>
    {
        return this.http.post<any>(agendaPrazos+"/busca",obj).pipe(
            map(res =>{
               return res;              
            }),catchError(error =>{
               return throwError(error);
            })
         );
         
    }

    public buscaPrazoById(idPrazo:number):Observable<any>
    {
        return this.http.get<any>(agendaPrazos+"/"+idPrazo).pipe(
            map(res =>{
               return res;              
            }),catchError(error =>{
               return throwError(error);
            })
         );
         
    }
}