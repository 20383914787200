import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { loginProvider } from 'services/login.provider';
//import { AdministradorProvider } from './services/administrador.provider';

@Injectable()
export class AuthGuard implements CanActivate{

    constructor(
        private route: Router,
        private service_ : loginProvider
        //private _service : appService
        ){}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {                       
        if(this.service_.isLoggedAdm() == null)
            this.route.navigate(['/loginAdm'])

        return true;
        //return true;
    }
}