import { Observable, throwError, observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { catchError, retry, take, map } from 'rxjs/operators';
import { cliente, processosBusca, processos_api } from '../const';
import { Injectable } from '@angular/core';
import { Clientes } from 'models/Clientes';

@Injectable()
export class ProcessoProvider {

   constructor(private http: HttpClient) { }

   public buscaProcesso(filtro): Observable<any> {
      return this.http.post<any>(processosBusca, filtro).pipe(
         map(res => {
            return res;
         }), catchError(error => {
            return throwError(error);
         })
      );
   }

   public buscaTipoPeticao(): Observable<any> {
      return this.http.get<any>(processos_api+"/tiposPeticao").pipe(
         map(res => {
            return res;
         }), catchError(error => {
            return throwError(error);
         })
      );
   }

   public buscaClasseNice(): Observable<any> {
      return this.http.get<any>(processos_api+"/classesNice").pipe(
         map(res => {
            return res;
         }), catchError(error => {
            return throwError(error);
         })
      );
   }

   public buscaClasseNiceById(num:string): Observable<any> {
      return this.http.get<any>(processos_api+"/classesNice/"+num).pipe(
         map(res => {
            return res;
         }), catchError(error => {
            return throwError(error);
         })
      );
   }

   public cadastrarProcesso(processo: any): Observable<any> {
      return this.http.post<any>(processos_api, processo).pipe(
         map(res => {
            return res
         }, catchError(err => {
            return throwError(err)
         }))
      )
   }



   public AlterarProcesso(processo: any): Observable<any> {
      return this.http.put<any>(processos_api, processo).pipe(
         map(res => {
            return res
         }, catchError(err => {
            return throwError(err)
         }))
      )
   }

   public buscaNumeroCadastrpProcesso(nProcesso: string): Observable<any> {
      return this.http.get<any>(processosBusca + "/processoCliente/" + nProcesso).pipe(
         map(res => {
            return res;
         }, err => {
            return err;
         })
      )

   }

   public excluiModulos(idModulo, idProcesso, modulo): Observable<any> {
      return this.http.delete<any>(processos_api + "/Modulos?idProcesso=" + idProcesso + "&modulo=" + modulo + "&Id=" + idModulo).pipe(
         map(res => {
            return res;
         }, err => {
            return err;
         })
      )

   }

   public buscaNumeroProcesso(nProcesso: string): Observable<any> {
      return this.http.get<any>(processosBusca + "/" + nProcesso).pipe(
         map(res => {
            return res;
         }, err => {
            return err;
         })
      )

   }


}