import { Component, OnInit } from '@angular/core';
import { ParametrosCadastraisProvider } from 'services/parametrosCadastrais.provider';
import { usuarioProvider } from 'services/usuario.provider';

@Component({
  selector: 'app-agenda-prazos-patentes',
  templateUrl: './agenda-prazos-patentes.component.html',
  styleUrls: ['./agenda-prazos-patentes.component.css']
})
export class AgendaPrazosPatentesComponent implements OnInit {

  constructor(private service_parametros: ParametrosCadastraisProvider,
    private service_usuario: usuarioProvider)  { }

  parametros: any = [];
  usuarios: any = [];
  resultados: Array<any> = [];

  prazoEscolhido:any = null;

  ngOnInit(): void {
    this.service_parametros.getParametrosAgendaPrazo().subscribe(res => {
      this.parametros = res;
      console.log(res);      
    });

    this.service_usuario.getUsuariosAtivos().subscribe((res) => {
      this.usuarios = res;
    });
  }

  recebeResultadosFiltro(resultados:Array<any>){
    this.resultados = resultados;  
  }

  recebePrazoEscolhido(id:string)
  {
    console.log(id);
    
    this.prazoEscolhido = id;    
  }

}
