import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-resultado-busca',
  templateUrl: './resultado-busca.component.html',
  styleUrls: ['./resultado-busca.component.css']
})
export class ResultadoBuscaComponent implements OnChanges {

  @Input() resultadosBusca = [];
  @Output() prazoEscolhido = new EventEmitter()
  pag: Number = 1;
  contador: Number = 20;

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes);    
  }

  confereItems() {
    for (let i = 0; i < this.resultadosBusca.length; i++) {
      let t = <HTMLInputElement>document.getElementById("seleciona_" + this.resultadosBusca[i]);
      if (t != null) {
        t.checked = true;
      }
    }
  }

  EditaPrazo(id:any)
  {
    console.log(id);
     ;
    this.prazoEscolhido.emit(id);
  }
}
