import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { cobranca } from 'const';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseService } from './BaseService';

@Injectable({
  providedIn: 'root'
})
export class Cobranca {

  constructor(private http: HttpClient) {}

   gerandoCobranca(obj): Observable<any>{
     return this.http.post<any>(cobranca,obj).pipe(
       map( res => {
         return res;
       }), catchError(err =>{
         console.log("erro no provider: " + err)
         return throwError(err);
       })
    )
   }

   gerandoWordCobranca(obj): Observable<any>{
      ;
    return this.http.post<any>(cobranca + '/wordCobranca',obj).pipe(
      map( res => {
        return res;
      }), catchError(err =>{
        console.log("erro no provider: " + err)
        return throwError(err);
      })
   )
   }
}
