import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-peticoes-marca',
  templateUrl: './peticoes-marca.component.html',
  styleUrls: ['./peticoes-marca.component.css']
})
export class PeticoesMarcaComponent implements OnInit {

  constructor() { }
  editar:boolean = false;
  peticao: {
    id: "";
    idProcessoPatente: "";
    numero: "";
    tipoPeticao: "";
    data: "";
    nomeRazaoSocial: "";
    idProcessoPatenteNavigation: "";
  };


  peticaoFormulario:FormGroup = new FormGroup({
    protocolo: new FormControl(null),
    data: new FormControl(null),
    servico: new FormControl(null),
    tipoPeticao: new FormControl(null),
    requerente: new FormControl(null),
  })
  
  editando:boolean = false;
  indiceEdit:number = 0;

  @Input() peticoes: Array<{
    protocolo: "",
    data: "",
    servico: "",
    tipoPeticao: "",
    requerente: "",
  }> = [];

  peticaoForm: FormArray = new FormArray([]);

  @Input() processoExisteRpi:boolean = false;
  @Output() peticoesOut = new EventEmitter();

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.peticoes.firstChange) {
      this.peticoes.map((pet, i) => {
        this.adicionaPeticaoForm(pet.protocolo,pet.data,pet.servico,pet.tipoPeticao,pet.requerente);
      });
    }
  }

  limpaPeticao(){
    this.peticaoFormulario =  new FormGroup({
      protocolo: new FormControl(null),
      data: new FormControl(null),
      servico: new FormControl(null),
      tipoPeticao: new FormControl(null),
      requerente: new FormControl(null),
    })
    this.eventChange();
  }

  editaPeticao(indice:number)
  {
    this.editando = true;
    this.indiceEdit = indice;
    this.peticaoFormulario.setValue(this.peticaoForm.value[indice]);
    this.eventChange();
  }

  removerFormAnuidade(indice:number)
  {
    this.peticaoForm.removeAt(indice);
    this.eventChange();
  }

  eventChange()
  {
    this.peticoesOut.emit(this.peticaoForm);
  }

  adicionarPeticao()
  {  if(!this.editando)
      this.peticaoForm.push(this.peticaoFormulario)
      else{
        this.editando = false;    
        this.peticaoForm.controls[this.indiceEdit].setValue(this.peticaoFormulario.value) 
        this.indiceEdit = 0;
      }
      this.eventChange();
  }

  removerPeticaoForm(indice: number) {
    this.peticaoForm.removeAt(indice);
  }

  adicionaPeticaoForm(
    protocolo,
    data,
    servico,
    tipoPeticao,
    requerente,
  ) {
    // ;
    this.peticaoForm.push(
      new FormGroup({
        protocolo: new FormControl(protocolo),
        data: new FormControl(data),
        servico: new FormControl(servico),
        tipoPeticao: new FormControl(tipoPeticao),
        requerente: new FormControl(requerente),
      })
    );

    this.eventChange();
  }

}
