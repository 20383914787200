import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { ID_USUARIO } from 'app/constantes/variaveis';

@Component({
  selector: 'app-processos-vinculados-desenho',
  templateUrl: './processos-vinculados-desenho.component.html',
  styleUrls: ['./processos-vinculados-desenho.component.css']
})
export class ProcessosVinculadosDesenhoComponent implements OnChanges {

  @Output() processosVinculadosPatentes = new EventEmitter();
  processosVinculados:FormGroup = new FormGroup({
    processo:new FormControl(null),
    tipo: new FormControl(null),
    assunto:new FormControl(null),
    situacao:new FormControl(null),
    usuario: new FormControl(ID_USUARIO)
  });

  @Input() recebeProcVinc = [];
  editando:boolean = false;
  indiceEdit:number = 0;

  processosArray:FormArray = new FormArray([])

  constructor() { }
  ngOnChanges(changes: SimpleChanges): void {
    if(!changes.recebeProcVinc.firstChange)
    {
      console.log(changes.recebeProcVinc.currentValue);
      changes.recebeProcVinc.currentValue.forEach(proc => {
        this.processosVinculados.setValue(proc);
        this.processosArray.push(this.processosVinculados);
        this.eventVinculadosProcessoPatente();
      });
    }
  }

  ngOnInit(): void {
  }

  editaProcesso(indice:number)
  {
    this.editando = true;
    this.indiceEdit = indice;      
    this.processosVinculados.setValue(this.processosArray.value[indice]);
    console.log(this.processosVinculados);
    
  }

  eventVinculadosProcessoPatente(){
    this.processosVinculadosPatentes.emit(this.processosArray);
  }

  removerFormProcesso(indice:number)
  {
    this.processosArray.removeAt(indice);
  }

  limparProcesso()
  {
    this.editando = false;
    this.processosVinculados = new FormGroup({
      processo:new FormControl(null),
      tipo: new FormControl(null),
      assunto:new FormControl(null),
      situacao:new FormControl(null),
      usuario: new FormControl(ID_USUARIO)
    });

  }

  AdicionarProcesso()
  {
    if(!this.editando)
      this.processosArray.push(this.processosVinculados);
    else{
      this.editando = false;    
      this.processosArray.controls[this.indiceEdit].setValue(this.processosVinculados.value) 
      this.indiceEdit = 0;
    }

    this.eventVinculadosProcessoPatente();
  }

}
