import { Observable, throwError, observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { catchError, retry, take, map } from 'rxjs/operators';
import { casaDosDados } from '../const';
import { Injectable } from '@angular/core';
import { PesquisaNomeEmpresarialCNAEDTO } from 'DTO/PesquisaNomeEmpresarialDTO';

@Injectable()
export class pesquisaNomeEmpresarialProvider {
   headersConfig = { 'Content-Type': 'application/json', 'Accept': 'application/json' };
   idCliente = localStorage.getItem('idUsuario');

   constructor(private http: HttpClient) { }

   getPesquisaByPage(dtParams): Observable<any> {
      dtParams["userId"] = this.idCliente;
      return this.http.post<any>(casaDosDados + '/Pesquisa/ReturnDataByPage', dtParams, { headers: this.headersConfig })
         .pipe(
            map(res => {
               return res;
            }), catchError(error => {
               return throwError(error);
            })
         );
   }
   getPesquisaAll(params): Observable<any> {
      params["userId"] = this.idCliente;
      return this.http.post<any>(casaDosDados + '/Pesquisa/ReturnData', params, { headers: this.headersConfig })
         .pipe(
            map(res => {
               return res;
            }), catchError(error => {
               return throwError(error);
            })
         );
   }

   getProgress(uniqueId): Observable<any> {
      return this.http.get(casaDosDados + '/Pesquisa/Progress?uniqueId=' + uniqueId, { headers: this.headersConfig })
         .pipe(
            map(res => {
               return res;
            }), catchError(error => {
               return throwError(error);
            })
         );
   }
   cancelExport(uniqueId): Observable<any> {
      return this.http.get(casaDosDados + '/Pesquisa/CancelExport?uniqueId=' + uniqueId, { headers: this.headersConfig })
         .pipe(
            map(res => {
               return res;
            }), catchError(error => {
               return throwError(error);
            })
         );
   }

   getCNAE(): Observable<any> {
      return this.http.get(casaDosDados + '/Pesquisa/GetCNAE', { headers: this.headersConfig })
         .pipe(
            map(res => {
               return res;
            }), catchError(error => {
               return throwError(error);
            })
         );
   }

   getNaturezaJuridica(): Observable<any> {
      return this.http.get(casaDosDados + '/Pesquisa/GetNaturezaJuridica', { headers: this.headersConfig })
         .pipe(
            map(res => {
               return res;
            }), catchError(error => {
               return throwError(error);
            })
         );
   }


   export(params): Observable<any> {
      params["userId"] = this.idCliente;
      return this.http.post<any>(casaDosDados + '/Pesquisa/Export', params, { headers: this.headersConfig })
         .pipe(
            map(res => {
               return res;
            }), catchError(error => {
               return throwError(error);
            })
         );
   }

   downloadFiles(filename) {
      return casaDosDados + '/Pesquisa/DownloadFiles?userId=' + this.idCliente + '&filename=' + filename;
   }

   getMunicipios(uf): Observable<any> {
      return this.http.get(casaDosDados + '/Pesquisa/GetMunicipios?uf=' + uf, { headers: this.headersConfig })
         .pipe(
            map(res => {
               return res;
            }), catchError(error => {
               return throwError(error);
            })
         );
   }
}