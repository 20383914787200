import {Observable, throwError, observable} from 'rxjs';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import {catchError, retry, take, map} from 'rxjs/operators';
import { usuario, login, parametroPrazos } from '../const';
import { Injectable } from '@angular/core';
import { Clientes } from 'models/Clientes';
import { Login } from 'models/Login';
import { LoginAdm } from 'models/LoginAdm';
import { Router } from '@angular/router';
import { LoginCliente } from 'models/LoginCliente';
import { Usuario } from 'models/Usuario';
import { usuarioDTO } from 'models/UsuarioDTO';
import { ParametrosPrazos } from 'models/ParametrosPrazos';
import { requestParametroPrazosDTO } from 'DTO/requestParametroPrazosDTO';


@Injectable()
export class ParametrosPrazosProvider{
    constructor(private http : HttpClient,
        private route: Router){ }

    public getParamentos():Observable<any>{
        return this.http.get<any>(parametroPrazos+'/'+localStorage.getItem('idUsuario')).pipe(
            map(res =>{
            return res;
            }),catchError(err =>{
            return throwError(err);
        }))
    } 

    public saveParametros(parametro:requestParametroPrazosDTO):Observable<any>{
        return this.http.post<any>(parametroPrazos,parametro).pipe(
            map(res =>{
               console.log(res);
            }),catchError(error =>{
               return throwError(error);
            })
         );
    }

    public updateParametros(parametro:requestParametroPrazosDTO):Observable<any>{
        return this.http.put<requestParametroPrazosDTO>(parametroPrazos,parametro).pipe(
            map(res =>{
               console.log(res);
            }),catchError(error =>{
               return throwError(error);
            })
         );
    }

}