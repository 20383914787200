import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { Observable, throwError } from "rxjs";
import { empresa } from "const";
import { map, catchError } from "rxjs/operators";
import { requestEmpresaDTO } from "DTO/requestEmpressaDTO";

@Injectable()
export class EmpresaProvider{
    constructor(private http : HttpClient,
    private route: Router){ }

    public getPatronosTipo():Observable<any>{
        return this.http.get<any>(empresa+"/patronosTipo").pipe(map(res =>{
            return res;
        },err =>{
            return null;
        }))
    }

    public getTiposEmpresasByCliente(idCliente:number, tipo:string):Observable<any>{
        return this.http.get<any>(empresa+"/Tipos?idCliente="+idCliente+"&tipoEmpresa="+tipo).pipe(map(res =>{
            return res;
        },err =>{
            return null;
        }))
    }

    public getEmpresaTipo(idCliente:number, tipo:number):Observable<any>{
        return this.http.get<any>(empresa+"/processo?idCliente="+idCliente+"&tipo="+tipo).pipe(map(res =>{
            return res;
        },err =>{
            return null;
        }))
    }

    public getEmpresaCadastradas(idCliente:string):Observable<any>{
        return this.http.get<any>(empresa+"/indice/"+idCliente).pipe(map(res =>{
            return res;
        },err =>{
            return null;
        }))
    }

    public getEmpresaById(idEmpresa:number):Observable<any>{
        return this.http.get<any>(empresa+"/"+idEmpresa).pipe(map(res =>{
            return res;
        },err =>{
            return null;
        }))
    }

    public deletePatronoRep(id:number):Observable<any>{
        return this.http.delete<any>(empresa+"/patrono/"+id).pipe(map(res =>{
            return res;
        },err =>{
            return null;
        }))
    }

    public saveEmpresa(obj:any):Observable<any>
    {
        return this.http.post<any>(empresa,obj).pipe(
            map(res =>{
               return res;
            }),catchError(error =>{
               return throwError(error);
            })
         );
    }

    public updateEmpresa(obj:requestEmpresaDTO):Observable<any>
    {
        return this.http.put<any>(empresa,obj).pipe(
            map(res =>{
               console.log(res);
               console.log("Empresa Editada com sucesso");
            }),catchError(error =>{
               return throwError(error);
            })
         );
    }

}