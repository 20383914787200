import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { PaisesUnionista } from 'models/PaisesUnionista';
import { Usuario } from 'models/Usuario';
import { EmpresaProvider } from 'services/empresa.provider';
import { ParametrosCadastraisProvider } from 'services/parametrosCadastrais.provider';
import { ProcessoProvider } from 'services/processo.provider';
import { usuarioProvider } from 'services/usuario.provider';

@Component({
  selector: 'app-dados-gerais-marca',
  templateUrl: './dados-gerais-marca.component.html',
  styleUrls: ['./dados-gerais-marca.component.css']
})
export class DadosGeraisMarcaComponent implements OnChanges {

  paises_unionista: Array<PaisesUnionista> = [];
  usuarios: Array<Usuario> = [];
  tipo_prazo: any = [];
  tipos_peticao: any = [];
  tipos_classeNice = [];
  usuar: Array<any> = [];

  constructor(
    private service: ProcessoProvider,
    private service_usuario: usuarioProvider,
    private service_Empresa: EmpresaProvider,
    private service_parametros: ParametrosCadastraisProvider
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.service_parametros.getPaisesCadastrados().subscribe((res) => {
      this.paises_unionista = res;
    });

    this.service_usuario.getUsuariosAtivos().subscribe((res) => {
      this.usuarios = res;
    });

    this.service_parametros.getTipoPrazoPorCliente().subscribe((res) => {
      this.tipo_prazo = res;
    });

    this.service.buscaTipoPeticao().subscribe((res) => {
      this.tipos_peticao = res;
    });

    this.service.buscaClasseNice().subscribe((res) => {
      this.tipos_classeNice = res;
    });
    if (!changes.recebeDadosGerais.firstChange) {
      debugger;
      this.service_usuario.getUsuariosAtivos().subscribe((res) => {
        this.usuarios = res;
        setTimeout(() => {
          this.dadosGeraisForm.setValue(changes.recebeDadosGerais.currentValue);
          this.eventDadosGeraisProcesso();
        }, 150);
      });
    }
  }

  @Output() dadosGerais = new EventEmitter();
  @Input() recebeDadosGerais = new EventEmitter();

  dadosGeraisForm: FormGroup = new FormGroup({
    pastaReferencia: new FormControl(null),
    referenciaCliente: new FormControl(null),
    referenciaFinanceira: new FormControl(null),
    referenciaAgenteExterior: new FormControl(null),
    outros: new FormControl(null),
    patrono: new FormControl(null),
    patronoId: new FormControl(null),
    responsavel: new FormControl(null),
    responsavelId: new FormControl(null),
    estagiario: new FormControl(null),
    estagiarioId: new FormControl(null),
    associado: new FormControl(null),
    associadoId: new FormControl(null),
    terceiros: new FormControl(false),
    altoRenome: new FormControl(false),
    colidencia: new FormControl(false),
    abandonado: new FormControl(false),
    arquivoMorto: new FormControl(false),
    grupoMarcas: new FormControl(false),
    refGrupoMarcas: new FormControl(""),
    grupoEmpresas: new FormControl(false),
    refGrupoEmpresas: new FormControl(""),
    instrucoes: new FormControl(false),
    instrucoesFaturamento: new FormControl(false),
    textoInstrucoes: new FormControl(null),
    textoFaturamento: new FormControl(null),
  });

  eventDadosGeraisProcesso() {
    debugger;
    //parece ser possivel extrair as informacoes dessa forma, apesar de nao parecer uma 
    //boa pratica
    if(this.dadosGeraisForm.controls.responsavelId.value != null){
      var responsavel = this.usuarios.find(x => x.id == this.dadosGeraisForm.controls.responsavelId.value);
      this.dadosGeraisForm.patchValue({
        responsavel: responsavel.nome,
        responsavelId: responsavel.id,
      });
    }
    if(this.dadosGeraisForm.controls.patronoId.value != null){
      var patrono = this.usuarios.find(x => x.id == this.dadosGeraisForm.controls.patronoId.value);
      this.dadosGeraisForm.patchValue({
        patrono: patrono.nome,
        patronolId: patrono.id,
      });
    }
    if(this.dadosGeraisForm.controls.estagiarioId.value != null){
      var estagiario = this.usuarios.find(x => x.id == this.dadosGeraisForm.controls.estagiarioId.value);
      this.dadosGeraisForm.patchValue({
        estagiario: estagiario.nome,
        estagiarioId: estagiario.id,
      });
    }
    if(this.dadosGeraisForm.controls.associadoId.value != null){
      var associado = this.usuarios.find(x => x.id == this.dadosGeraisForm.controls.associadoId.value);
      this.dadosGeraisForm.patchValue({
        associado: associado.nome,
        associadoId: associado.id,
      });
    }
    this.dadosGerais.emit(this.dadosGeraisForm);
  }

  adicionaResponsavelDadosForm(id, nome){

  }
}
