import { Observable, throwError, observable } from "rxjs";
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { catchError, retry, take, map } from "rxjs/operators";
import { cliente, patente } from "../const";
import { Injectable } from "@angular/core";
import { Clientes } from "models/Clientes";

@Injectable()
export class patenteProvider {
  constructor(private http: HttpClient) {}

  public getProcessoByNumero(numero): Observable<any> {
    return this.http.get<any>(patente+"/"+numero).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public cadastrarProcessoCliente(processo:any): Observable<any> {
    return this.http.post<any>(patente+"/Processos/Cadastrar",processo).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public alterarProcessoCliente(processo:any): Observable<any> {
    return this.http.put<any>(patente+"/Processos/Cadastrar",processo).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public getProcessoClienteById(id): Observable<any> {
    return this.http.get<any>(patente+"/Processos/Clientes/"+id).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public getProcessoDesenhoClienteById(id): Observable<any> {
    return this.http.get<any>(patente+"/ProcessosDesenho/Clientes/"+id).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }


  public getNumeroNovoProcessoByCliente(idCliente:any): Observable<any> {
    return this.http.get<any>(patente+"/Processos?idCliente="+idCliente).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public buscaProcessosClientes(busca:any) : Observable<any>{
    return this.http.post<any>(patente+"/Processos/Clientes",busca).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public buscaProcessosClientesDesenho(busca:any) : Observable<any>{
    return this.http.post<any>(patente+"/ProcessosDesenho/Clientes",busca).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }



  public getSituacaoProcessoDespacho(numero): Observable<any> {
    return this.http.get<any>(patente+"/Despacho/"+numero).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public GetProcessosByBusca(obj): Observable<any> {
    console.log(obj);
    return this.http.post<any>(patente + "/Busca", obj).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public cadastraPrazoProcesso(prazo:any) : Observable<any>{
    return this.http.post<any>(patente+"/AgendaPrazos",prazo).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public buscaPrazosProcesso(busca:any) : Observable<any>{
    return this.http.post<any>(patente+"/AgendaPrazos/Busca",busca).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public buscaPrazosProcessoDesenho(busca:any) : Observable<any>{
    return this.http.post<any>(patente+"/AgendaPrazosDesenho/Busca",busca).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public buscaPrazosPorId(id:any) : Observable<any>{
    return this.http.get<any>(patente+"/AgendaPrazos/Busca/"+id).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public buscaPrazosAnuidadesProcesso(busca:any) : Observable<any>{
    return this.http.post<any>(patente+"/AgendaAnuidades/Busca",busca).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public buscaPrazosAnuidadesDesenhos(busca: any) : Observable<any>{
     ;
    return this.http.post<any>(patente+"/AgendaAnuidadesDesenho/Busca",busca).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public buscaAnuidadeForm(busca:any) : Observable<any>{
    return this.http.post<any>(patente+"/AgendaAnuidades/BuscaFormulario",busca).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public gerarRelarorio(busca:any) : Observable<any>{
    return this.http.post<any>(patente+"/AgendaAnuidades/Relatorio",busca).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public gerarRelarorioProcesso(busca:any) : Observable<any>{
    return this.http.post<any>(patente+"/BuscaProcessos/Relatorio",busca).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public EditaAnuidadeForm(obj:any) : Observable<any>{
    return this.http.put<any>(patente+"/AgendaAnuidades/Edita",obj).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
}
