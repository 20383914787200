import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-datas-marca',
  templateUrl: './datas-marca.component.html',
  styleUrls: ['./datas-marca.component.css']
})
export class DatasMarcaComponent implements OnInit {

  @Output() formDatasProcesso = new EventEmitter();
  @Input()  datas:any = {};
  @Input()  processoExisteRpi:boolean = false;

  formDatas:FormGroup = new FormGroup({
    dataConcessao : new FormControl(null),
    dataDeposito : new FormControl(null),
    dataVigenciaRegistro : new FormControl(null),
    dataRecebimentoInpi : new FormControl(null),
    dataRegistro : new FormControl(null),
    dataInicioCaducidade : new FormControl(null),
    prazoOrdinarioInicio : new FormControl(null),
    prazoOrdinarioFim : new FormControl(null),
    prazoExtraordinarioInicio : new FormControl(null),
    prazoExtraordinarioFim : new FormControl(null),
    prorrogacaoPrazoOrdinario : new FormControl(null),
    prorrogacaoPrazoExtraordinario : new FormControl(null),
  });

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    
    if(!changes.datas.firstChange)
    {
      
      this.formDatas.setValue(this.datas)
      this.mudanca()
    }           
  }

 
  mudanca(){
    this.formDatasProcesso.emit(this.formDatas);
  }

}
