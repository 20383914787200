import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { Observable, throwError } from "rxjs";
import { parametrosCadastrais, parametrosCadastraisMarcas } from "const";
import { catchError, map } from "rxjs/operators";
import { RequestPadrapTipoCadastroDTO } from "DTO/requestPadraoTipoCadastroDTO";
import { PaisesUnionista } from "models/PaisesUnionista";
import { RequesPaisesUnionistaDTO } from "DTO/requestPaisesUnionistaDTO";
import { RequestComarcaJudicialDTO } from "DTO/RequestComarcaJudicialDTO";

@Injectable()
export class ParametrosCadastraisProvider{
    constructor(private http : HttpClient,
        private route: Router){ }


    public getParametrosMarcas():Observable<any>{
        return this.http.get<any>(parametrosCadastraisMarcas).pipe(
            map(res =>{
                return res;
                }),catchError(err =>{
            return throwError(err);
        }))
    } 

    public deleteApresentacao(id):Observable<any>{
        return this.http.delete<any>(parametrosCadastraisMarcas+"/Apresentacao/"+id).pipe(
            map(res =>{
                return res;
                }),catchError(err =>{
            return throwError(err);
        }))
    } 

    public addApresentacao(obj):Observable<any>{
        console.log(obj);        
        return this.http.post<any>(parametrosCadastraisMarcas+"/Apresentacao/", obj).pipe(
            map(res =>{
                return res;
                }),catchError(err =>{
            return throwError(err);
        }))
    } 

    public getParametros():Observable<any>{
        return this.http.get<any>(parametrosCadastrais).pipe(
            map(res =>{
                return res;
                }),catchError(err =>{
            return throwError(err);
        }))
    } 
    
    public getParametrosAgendaPrazo():Observable<any>{
        let idCliente = localStorage.getItem("idCliente");
        return this.http.get<any>(parametrosCadastrais+"/agenda/"+idCliente).pipe(
            map(res =>{
                return res;                
                }),catchError(err =>{
            return throwError(err);
        }))
    }

    public getPaisesCadastrados():Observable<any>{
        return this.http.get<any>(parametrosCadastrais+"/paisesUnionista").pipe(
            map(res =>{
                return res;
                }),catchError(err =>{
            return throwError(err);
        }))
    } 

    public getTipoPrazoPorCliente():Observable<any>{
        let idCliente = localStorage.getItem("idCliente");
        return this.http.get<any>(parametrosCadastrais+"/tipoPrazo/"+idCliente).pipe(
            map(res =>{
                return res;
                }),catchError(err =>{
            return throwError(err);
        }))
    } 

    public getParametrosByIdCliente(idCliente:number):Observable<any>{
        return this.http.get<any>(parametrosCadastrais+"/"+idCliente).pipe(
            map(res =>{
                return res;
                }),catchError(err =>{
            return throwError(err);
        }))
    } 


    public getCidadeComarca(cidade:string):Observable<any>{
        return this.http.get<any>(parametrosCadastrais+"/cidadeComarca/"+cidade).pipe(
            map(res =>{
                return res;
                }),catchError(err =>{
            return throwError(err);
        }))
    } 

    public delete(obj:RequestPadrapTipoCadastroDTO):Observable<any>{
        return this.http.post<any>(parametrosCadastrais+"/padraoTipoDelete",obj).pipe(map(res =>{
            return res;
        },err =>{
            return null;
        }))
    }

    public deletePais(idPais:number):Observable<any>{
        return this.http.delete<any>(parametrosCadastrais+"/paisesUnionista/"+idPais).pipe(map(res =>{
            return res;
        },err =>{
            return null;
        }))
    }

    public insertClassificacao(obj:any):Observable<any>{
        return this.http.post<any>(parametrosCadastrais+"/classificaoViena",obj).pipe(map(res =>{
            return res;
        },err =>{
            return null;
        }))
    }


    public updateClassificacao(obj:any):Observable<any>{
        return this.http.put<any>(parametrosCadastrais+"/classificaoViena",obj).pipe(map(res =>{
            return res;
        },err =>{
            return null;
        }))
    }


    public deleteClassificao(id:number):Observable<any>{
        return this.http.delete<any>(parametrosCadastrais+"/classificaoViena/"+id).pipe(map(res =>{
            return res;
        },err =>{
            return null;
        }))
    }


    public deleteComarca(idComarca:number):Observable<any>{
        return this.http.delete<any>(parametrosCadastrais+"/comarcaJudicial/"+idComarca).pipe(map(res =>{
            return res;
        },err =>{
            return null;
        }))
    }

    public update(obj:RequestPadrapTipoCadastroDTO):Observable<any>{
        return this.http.put<any>(parametrosCadastrais+"/padraoTipo",obj).pipe(map(res =>{
            return res;
        },err =>{
            return null;
        }))
    }

    public insertTipoPrazo(obj:any):Observable<any>{
        return this.http.post<any>(parametrosCadastrais+"/tipoPrazo",obj).pipe(map(res =>{
            return res;
        },err =>{
            return null;
        }))
    }

    public updateTipoPrazo(obj:any):Observable<any>{
        return this.http.put<any>(parametrosCadastrais+"/tipoPrazo",obj).pipe(map(res =>{
            return res;
        },err =>{
            return null;
        }))
    }

    public removeTipoPrazo(id:number):Observable<any>{
        return this.http.delete<any>(parametrosCadastrais+"/tipoPrazo/"+id).pipe(map(res =>{
            return res;
        },err =>{
            return null;
        }))
    }


    public insertFatoGerador(obj:any):Observable<any>{
        return this.http.post<any>(parametrosCadastrais+"/FatoGerador",obj).pipe(map(res =>{
            return res;
        },err =>{
            return null;
        }))
    }

    public updateFatoGerador(obj:any):Observable<any>{
        return this.http.put<any>(parametrosCadastrais+"/FatoGerador",obj).pipe(map(res =>{
            return res;
        },err =>{
            return null;
        }))
    }

    public removeFatoGerador(id:number):Observable<any>{
        return this.http.delete<any>(parametrosCadastrais+"/FatoGerador/"+id).pipe(map(res =>{
            return res;
        },err =>{
            return null;
        }))
    }

    public insertSituacaoPrazo(obj:any):Observable<any>{
        return this.http.post<any>(parametrosCadastrais+"/SituacaoPrazo",obj).pipe(map(res =>{
            return res;
        },err =>{
            return null;
        }))
    }

    public updateSituacaoPrazo(obj:any):Observable<any>{
        return this.http.put<any>(parametrosCadastrais+"/SituacaoPrazo",obj).pipe(map(res =>{
            return res;
        },err =>{
            return null;
        }))
    }

    public removeSituacaoPrazo(id:number):Observable<any>{
        return this.http.delete<any>(parametrosCadastrais+"/SituacaoPrazo/"+id).pipe(map(res =>{
            return res;
        },err =>{
            return null;
        }))
    }

    
    public insertTipoPartesJD(obj:any):Observable<any>{
        return this.http.post<any>(parametrosCadastrais+"/TipoPartesJudicial",obj).pipe(map(res =>{
            return res;
        },err =>{
            return null;
        }))
    }

    public updateTipoPartesJD(obj:any):Observable<any>{
        return this.http.put<any>(parametrosCadastrais+"/TipoPartesJudicial",obj).pipe(map(res =>{
            return res;
        },err =>{
            return null;
        }))
    }

    public removeTipoPartesJD(id:number):Observable<any>{
        return this.http.delete<any>(parametrosCadastrais+"/TipoPartesJudicial/"+id).pipe(map(res =>{
            return res;
        },err =>{
            return null;
        }))
    }

    public insertTipoAssuntoPadrao(obj:any):Observable<any>{
        return this.http.post<any>(parametrosCadastrais+"/TipoAssuntoPadrao",obj).pipe(map(res =>{
            return res;
        },err =>{
            return null;
        }))
    }

    public updateTipoAssuntoPadrao(obj:any):Observable<any>{
        return this.http.put<any>(parametrosCadastrais+"/TipoAssuntoPadrao",obj).pipe(map(res =>{
            return res;
        },err =>{
            return null;
        }))
    }

    public removeTipoAssuntoPadrao(id:number):Observable<any>{
        return this.http.delete<any>(parametrosCadastrais+"/TipoAssuntoPadrao/"+id).pipe(map(res =>{
            return res;
        },err =>{
            return null;
        }))
    }

    public insertTipoVaraJudicial(obj:any):Observable<any>{
        return this.http.post<any>(parametrosCadastrais+"/TipoVaraJudicial",obj).pipe(map(res =>{
            return res;
        },err =>{
            return null;
        }))
    }

    public updateTipoVaraJudicial(obj:any):Observable<any>{
        return this.http.put<any>(parametrosCadastrais+"/TipoVaraJudicial",obj).pipe(map(res =>{
            return res;
        },err =>{
            return null;
        }))
    }

    public removeTipoVaraJudicial(id:number):Observable<any>{
        return this.http.delete<any>(parametrosCadastrais+"/TipoVaraJudicial/"+id).pipe(map(res =>{
            return res;
        },err =>{
            return null;
        }))
    }

    public insertTipoAcaoJudicial(obj:any):Observable<any>{
        return this.http.post<any>(parametrosCadastrais+"/TipoAcaoJudicial",obj).pipe(map(res =>{
            return res;
        },err =>{
            return null;
        }))
    }

    public updateTipoAcaoJudicial(obj:any):Observable<any>{
        return this.http.put<any>(parametrosCadastrais+"/TipoAcaoJudicial",obj).pipe(map(res =>{
            return res;
        },err =>{
            return null;
        }))
    }

    public removeTipoAcaoJudicial(id:number):Observable<any>{
        return this.http.delete<any>(parametrosCadastrais+"/TipoAcaoJudicial/"+id).pipe(map(res =>{
            return res;
        },err =>{
            return null;
        }))
    }

    public insertCategoriasDominio(obj:any):Observable<any>{
        return this.http.post<any>(parametrosCadastrais+"/CategoriasDominio",obj).pipe(map(res =>{
            return res;
        },err =>{
            return null;
        }))
    }

    public updateCategoriasDominio(obj:any):Observable<any>{
        return this.http.put<any>(parametrosCadastrais+"/CategoriasDominio",obj).pipe(map(res =>{
            return res;
        },err =>{
            return null;
        }))
    }

    public removeCategoriasDominio(id:number):Observable<any>{
        return this.http.delete<any>(parametrosCadastrais+"/CategoriasDominio/"+id).pipe(map(res =>{
            return res;
        },err =>{
            return null;
        }))
    }

    public insertRegistroDeDominio(obj:any):Observable<any>{
        return this.http.post<any>(parametrosCadastrais+"/RegistroDeDominio",obj).pipe(map(res =>{
            return res;
        },err =>{
            return null;
        }))
    }

    public insertTipoClasse(obj:any):Observable<any>{
        return this.http.post<any>(parametrosCadastrais+"/TipoClasseNice",obj).pipe(map(res =>{
            return res;
        },err =>{
            return null;
        }))
    }

    public updateRegistroDeDominio(obj:any):Observable<any>{
        return this.http.put<any>(parametrosCadastrais+"/RegistroDeDominio",obj).pipe(map(res =>{
            return res;
        },err =>{
            return null;
        }))
    }

    public removeRegistroDeDominio(id:number):Observable<any>{
        return this.http.delete<any>(parametrosCadastrais+"/RegistroDeDominio/"+id).pipe(map(res =>{
            return res;
        },err =>{
            return null;
        }))
    }

    public insertDepartamentoSetor(obj:any):Observable<any>{
        return this.http.post<any>(parametrosCadastrais+"/DepartamentoSetor",obj).pipe(map(res =>{
            return res;
        },err =>{
            return null;
        }))
    }

    public updateDepartamentoSetor(obj:any):Observable<any>{
        return this.http.put<any>(parametrosCadastrais+"/DepartamentoSetor",obj).pipe(map(res =>{
            return res;
        },err =>{
            return null;
        }))
    }

    public removeDepartamentoSetor(id:number):Observable<any>{
        return this.http.delete<any>(parametrosCadastrais+"/DepartamentoSetor/"+id).pipe(map(res =>{
            return res;
        },err =>{
            return null;
        }))
    }
    
    public insertComarca(obj:RequestComarcaJudicialDTO):Observable<any>{
        return this.http.post<any>(parametrosCadastrais+"/comarcaJudicial",obj).pipe(map(res =>{
            return res;
        },err =>{
            return null;
        }))
    }

    public updateComarca(obj:RequestComarcaJudicialDTO):Observable<any>{
        return this.http.put<any>(parametrosCadastrais+"/comarcaJudicial",obj).pipe(map(res =>{
            return res;
        },err =>{
            return null;
        }))
    }

    public insertPais(obj:RequesPaisesUnionistaDTO):Observable<any>{
        return this.http.post<any>(parametrosCadastrais+"/paisesUnionista",obj).pipe(map(res =>{
            return res;
        },err =>{
            return null;
        }))
    }

    public updatePais(obj:RequesPaisesUnionistaDTO):Observable<any>{
        return this.http.put<any>(parametrosCadastrais+"/paisesUnionista",obj).pipe(map(res =>{
            return res;
        },err =>{
            return null;
        }))
    }

    public insert(obj:RequestPadrapTipoCadastroDTO):Observable<any>{
        return this.http.post<any>(parametrosCadastrais+"/padraoTipo",obj).pipe(map(res =>{
            return res;
        },err =>{
            return null;
        }))
    }
}