import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ID_CLIENTE } from 'app/constantes/variaveis';
import { MarcaClienteService } from 'services/marca-cliente.provider';
import { MarcaProvider } from 'services/marca.provider';

@Component({
  selector: 'app-processo-marcas',
  templateUrl: './processo-marcas.component.html',
  styleUrls: ['./processo-marcas.component.css']
})
export class ProcessoMarcasComponent implements OnInit {
  processoExisteRpi: boolean = false;

  constructor(
    private service: MarcaProvider,
    private route: ActivatedRoute,
    private serviceCliente: MarcaClienteService,
    private serviceClientes: MarcaClienteService
  ) { }

  @Input() recebeTipo = new EventEmitter();
  processoMarcaClienteForm: FormGroup = new FormGroup({
    id: new FormControl(null),
    dtInsercao: new FormControl(null),
    dtUltimaAtualizacao: new FormControl(null),
    idProcessoMarcas: new FormControl(null),
    existeRpi: new FormControl(false),
    idCliente: new FormControl(ID_CLIENTE),
    detalhes: new FormGroup({}),
    datasProcesso: new FormGroup({}),
    prioridadeUnionista: new FormArray([]),
    classificacao: new FormGroup({}),
    partesProcesso: new FormGroup({}),
    anuidade: new FormArray([]),
    peticoes: new FormArray([]),
    publicacoes: new FormArray([]),
    agendaPrazosPatentes: new FormArray([]),
    processosVinculados: new FormArray([]),
    historicoProcessos: new FormArray([]),
    dadosGerais: new FormGroup({}),
  });

  ngOnInit(): void {
    this.route.queryParamMap.subscribe(
      (res) => {
        if(res.get("processo") != null){
          this.serviceCliente
            .getProcessoClienteById(res.get("processo"))
            .subscribe((res) => {
              this.AtribuindoValoresProcesso(res);
            });
        }
      }
    )
  }
  
  novoProcesso = true;
  showBusca = true;
  idCliente = ID_CLIENTE;
  numeroProcesso = null;
  processoDetalhes: any = {};
  datas: any = {};
  prioridade: any = {};
  classificacao: any = {};
  partesProcesso: any = {};
  anuidades: any = {};
  peticao: any = {};
  publicacao: any = {};
  processosVinculados:any = {};
  historicoProc:any = [];
  dadosGerais:any = {};
  agendaPrazosPatentes:any = [];

  recebeDetalhesProcesso(proc: FormGroup) {
    this.processoMarcaClienteForm.controls["detalhes"] = proc;
  }

  recebeDatasProcesso(datas: FormGroup) {
    this.processoMarcaClienteForm.controls["datasProcesso"] = datas;
  }

  recebePrioridadeUnionista(prioridades: FormArray) {
    this.processoMarcaClienteForm.controls["prioridadeUnionista"] =
      prioridades;
  }

  recebeClassificacao(classificacao: FormGroup) {
    this.processoMarcaClienteForm.controls["classificacao"] = classificacao;
  }

  recebePartesProcesso(partes: FormGroup) {
    this.processoMarcaClienteForm.controls["partesProcesso"] = partes;    
  }

  recebeAnuidades(anuidades:FormArray){
    this.processoMarcaClienteForm.controls['anuidade'] = anuidades;
  };

  recebePeticoes(peticoes: FormArray) {
    this.processoMarcaClienteForm.controls["peticoes"] = peticoes;
  }
  // recebePublicacoes(publicacoes: FormArray) {
  //   debugger;
  //   this.processoMarcaClienteForm.controls["publicacoes"] = publicacoes;
  // }

  recebeProcessoVinculados(processosVinculado: FormArray) {
    this.processoMarcaClienteForm.controls["processosVinculados"] =
      processosVinculado;
  }

  recebeHistoricoProcesso(historicoProcessos: FormArray) {
    this.processoMarcaClienteForm.controls["historicoProcessos"] =
      historicoProcessos;
  }

  recebeDadosGeraisProcesso(dadosGerais: FormGroup) {
    this.processoMarcaClienteForm.controls["dadosGerais"] = dadosGerais;
  }

  AtribuindoValoresProcesso(res) {
    debugger;
    this.processoMarcaClienteForm.controls["id"].setValue(res._id);
    // this.processoMarcaClienteForm.controls["existeRpi"].setValue(
    //   res.existeRpi
    // );
    this.processoMarcaClienteForm.controls["idCliente"].setValue(
      res.idCliente
    );
    this.dadosGerais = res.dadosGerais;  
    if(res.idCliente != null && res.idCliente != 0){
      this.processoExisteRpi = true;
      this.showBusca = false;
    }
    this.novoProcesso = false;
    this.processoDetalhes = res.detalhes;
    this.numeroProcesso = this.processoDetalhes.processo;
    this.datas = res.datasProcesso;
    this.prioridade = res.prioridadeUnionistas;
    this.classificacao = res.classificacao;
    this.partesProcesso = res.partesProcesso;
    this.anuidades = res.anuidades;
    this.peticao = res.peticoes;
    this.publicacao = res.publicacoes;
    this.processosVinculados = res.processosVinculados;
    this.historicoProc = res.historicoProcessos;  
    this.agendaPrazosPatentes = res.agendaPrazos;  
    
  }

  RecebeProcessoByRpi(proc: any) {
    this.showBusca = false;
    debugger;
    if (proc != null) {
      this.serviceClientes.getProcessoByNumero(proc).subscribe(
        (res) => {
          debugger;
          console.log(res);
          this.processoExisteRpi = true;
          this.novoProcesso = false;          
          this.processoMarcaClienteForm.controls["existeRpi"].setValue(true);
          //this.idCliente = res.idCliente,
          this.processoExisteRpi = true;
          this.processoDetalhes = res.detalhes;
          this.datas = res.datasProcesso;
          this.prioridade = res.prioridadeUnionistas;
          this.classificacao = res.classificacao;
          this.partesProcesso = res.partesProcesso;
          this.anuidades = res.agenda;
          this.peticao = res.peticoes;
          this.publicacao = res.publicacoes;
          this.agendaPrazosPatentes = res.agendaPrazos;  
    
        },
        (err) => {}
      );
    } else {
      this.NovoProcesso();
    }
  }

  NovoProcesso() {
    this.showBusca = false;
    this.processoMarcaClienteForm.controls["existeRpi"].setValue(false);
    this.service.getNumeroNovoProcessoByCliente(ID_CLIENTE).subscribe(
      (res) => {
        this.processoExisteRpi = false;
        this.novoProcesso = true;
        //Definindo o novo número do processo
        this.processoDetalhes = {
          processo: res.processo,
          processoAnterior: "",
          processoDividido: "",
          classificacaoIpc: "",
          classificacaoCpc: "",
          titulo: "",
          resumo: "",
          situacaoProcesso: "",
        };
      },
      (err) => {
        console.log(err);
      }
    );
  }

  salvarProcesso() {
     debugger;
    let objEnvio = {
      _id: this.processoMarcaClienteForm.controls["id"].value,
      dtIserncao: Date.now,
      dtUltimaAtualizacao: Date.now,
      idUsuarioAlteracao: null,
      idUsuarioIncercao: null,
      //idProcessoMarcas: 'testando',
      existeRpi: this.processoMarcaClienteForm.controls["existeRpi"].value,
      idCliente: this.processoMarcaClienteForm.controls["idCliente"].value,
      detalhes: this.processoMarcaClienteForm.controls["detalhes"].value,
      datasProcesso:
        this.processoMarcaClienteForm.controls["datasProcesso"].value,
      prioridadeUnionistas:
        this.processoMarcaClienteForm.controls["prioridadeUnionista"].value,
      // pct: this.processoMarcaClienteForm.controls["pct"].value,
      classificao: this.processoMarcaClienteForm.controls["classificacao"].value,
      partesProcesso:
        this.processoMarcaClienteForm.controls["partesProcesso"].value,
      anuidades: this.processoMarcaClienteForm.controls["anuidade"].value,
      peticoes: this.processoMarcaClienteForm.controls["peticoes"].value,
      publicacoes:
        this.processoMarcaClienteForm.controls["publicacoes"].value,
      processosVinculados:
        this.processoMarcaClienteForm.controls["processosVinculados"].value,
      historicoProcessos:
        this.processoMarcaClienteForm.controls["historicoProcessos"].value,
      dadosGerais:
        this.processoMarcaClienteForm.controls["dadosGerais"].value,
    };
    //console.log(objEnvio);
    // ;
    //Verificando ID para alterar o objeto
    if (objEnvio._id != null) {
      objEnvio.idUsuarioAlteracao = objEnvio.idCliente;
      //objEnvio.idUsuarioIncercao = objEnvio.idCliente;
      this.serviceClientes.alterarProcessoCliente(objEnvio).subscribe(
        (res) => {
          alert("Processo atualizado com sucesso.");
        },
        (err) => {
          alert("Ocorreu um erro ao atualizar o processo");
        }
      );
    } else {
      objEnvio.idUsuarioIncercao = objEnvio.idCliente;
      this.serviceClientes.cadastrarProcessoCliente(objEnvio).subscribe(
        (res) => {
          alert("Processo cadastrado com sucesso.");
        },
        (err) => {
          console.log(err);
          alert("Ocorreu um erro ao cadastrar o processo");
        }
      );
    }
  }

}
