import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-designacao-marca',
  templateUrl: './designacao-marca.component.html',
  styleUrls: ['./designacao-marca.component.css']
})
export class DesignacaoMarcaComponent implements OnInit {

  @Input() designacao:any = {};
  @Input() processoExisteRpi: boolean = false;
  constructor() { }

  ngOnInit(): void {
  }

}
