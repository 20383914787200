import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ID_CLIENTE, ID_USUARIO } from "app/constantes/variaveis";
import { patenteProvider } from "services/patente.provider";

@Component({
  selector: "app-cadastro-prazos",
  templateUrl: "./cadastro-prazos.component.html",
  styleUrls: ["./cadastro-prazos.component.css"],
})
export class CadastroPrazosComponent implements OnChanges {
  @Input() usuarios = [];
  @Input() parametros: any = [];
  @Input() prazoId = null;

  erro: string = "";
  @Input() editaPrazo: boolean = false;

  cadastro: FormGroup = new FormGroup({
    id: new FormControl(null),
    dtPrazo: new FormControl(null, [Validators.required]),
    processo: new FormControl(null, [Validators.required]),
    assunto: new FormControl(null),
    assuntoLivre: new FormControl(null),
    pasta: new FormControl(null),
    despacho: new FormControl(null),
    responsavel: new FormControl(null),
    fatoGerador: new FormControl(null),
    tipoPrazo: new FormControl(null),
    idProcesso: new FormControl(null),
    ocorrenciaPrazo: new FormControl(null),
    idCliente: new FormControl(ID_CLIENTE),
    obeservacao: new FormControl(null),
    prazoBaixado: new FormControl(null),
    usuarioBaixa: new FormControl(null),
    dataBaixa: new FormControl(null),
    usuarioNome: new FormControl(null),
  });

  constructor(private service: patenteProvider) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.limpar();
    this.editaPrazo = false;
    if (this.prazoId != null) {
       ;
      this.service.buscaPrazosPorId(this.prazoId).subscribe(
        (res) => {
          console.log(res);
          this.cadastro.setValue(res);
          if (res.fatoGerador == 1) {
            this.desativarCampos();
          }

          this.editaPrazo = true;
        },
        (err) => {
          console.log(err);
        }
      );
    } else {
      this.prazoId = null;
      this.rebootModal();
    }
  }

  desativarCampos() {
     ;
    this.cadastro.controls["assunto"].disable();
    this.cadastro.controls["dtPrazo"].disable();
    this.cadastro.controls["tipoPrazo"].disable();
    this.cadastro.controls["processo"].disable();
    this.cadastro.controls["pasta"].disable();
    this.cadastro.controls["assuntoLivre"].disable();
    this.cadastro.controls["ocorrenciaPrazo"].disable();
    this.cadastro.controls["fatoGerador"].disable();
    this.cadastro.controls["responsavel"].disable();
  }

  ativarCampos() {
    this.cadastro.controls["assunto"].enable();
    this.cadastro.controls["dtPrazo"].enable();
    this.cadastro.controls["tipoPrazo"].enable();
    this.cadastro.controls["processo"].enable();
    this.cadastro.controls["pasta"].enable();
    this.cadastro.controls["assuntoLivre"].enable();
    this.cadastro.controls["ocorrenciaPrazo"].enable();
    this.cadastro.controls["fatoGerador"].enable();
    this.cadastro.controls["responsavel"].enable();
  }

  rebootModal(){
    this.limpar();
  }

  limpar() {
    this.editaPrazo = false;
    this.cadastro = new FormGroup({
      id: new FormControl(null),
      dtPrazo: new FormControl(null, [Validators.required]),
      processo: new FormControl(null, [Validators.required]),
      assunto: new FormControl(null),
      assuntoLivre: new FormControl(null),
      pasta: new FormControl(null),
      despacho: new FormControl(null),
      responsavel: new FormControl(null),
      fatoGerador: new FormControl(null),
      tipoPrazo: new FormControl(null),
      idProcesso: new FormControl(null),
      ocorrenciaPrazo: new FormControl(null),
      idCliente: new FormControl(ID_CLIENTE),
      obeservacao: new FormControl(null),
      prazoBaixado: new FormControl(null),
      usuarioBaixa: new FormControl(null),
      dataBaixa: new FormControl(null),
      usuarioNome: new FormControl(null),
    });
  }

  baixarPrazo() {
    if (this.cadastro.value.prazoBaixado) {
      console.log(
        new Date().getFullYear() +
          "-" +
          (new Date().getMonth() + 1) +
          "-" +
          new Date().getDate()
      );
      this.cadastro.controls["usuarioBaixa"].setValue(ID_USUARIO);
      this.cadastro.controls["dataBaixa"].setValue(
        new Date().getFullYear() +
          "-" +
          (new Date().getMonth() + 1) +
          "-" +
          new Date().getDate()
      );
    }
  }

  onSubmit() {
    if (this.cadastro.valid) {
      this.ativarCampos();
      console.log(this.cadastro.value);
       ;
      this.service.cadastraPrazoProcesso(this.cadastro.value).subscribe(
        (res) => {
          if (res.cadastrado) {
            alert("Prazo cadastrado com sucesso.");
            this.limpar();
          } else {
            this.erro = res.mensagem;
          }
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }
}
