import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { marca } from 'const';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MarcaProvider {

  constructor(private http: HttpClient) { }

  public getApresentacaoMarca(){
    return this.http.get<any>(marca+"/ApresentacaoMarcas").pipe(
      map((res) => {
       return res.map(apresentacao =>{
          return apresentacao.tipo
        })   
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public getPaises(){
    return this.http.get<any>(marca+"/SiglaPaises").pipe(
      map((res) => {
       return res.map(marca =>{
          return marca.nome
        })   
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public getNatureza(){
    return this.http.get<any>(marca+"/NaturezaMarca").pipe(
      map((res) => {
       return res.map(marca =>{
          return marca.portugues
        })   
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public getClasseViena(){
    return this.http.get<any>(marca+"/ClasseViena").pipe(
      map((res) => {
       return res.map(marca =>{
          return marca.codigo
        })   
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  

  // public getProcessoByNumero(numero): Observable<any> {
  //    ;
  //   return this.http.get<any>(marca+"/Marcas/Busca/"+numero).pipe(
  //     map((res) => {
  //        ;
  //       return res;
  //     }),
  //     catchError((error) => {
  //       return throwError(error);
  //     })
  //   );
  // }

  public cadastrarProcessoCliente(processo:any): Observable<any> {
    return this.http.post<any>(marca+"/Marcas/Processos/Cadastrar",processo).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public GetProcessosByBusca(obj): Observable<any> {
    console.log(obj);
    return this.http.get<any>(marca + "/Marcas/filters?", obj).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public getNumeroNovoProcessoByCliente(idCliente:any): Observable<any> {
     ;
    return this.http.get<any>(marca+"/Processos?idCliente="+idCliente).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public getProcessoClienteById(id): Observable<any> {
    return this.http.get<any>(marca+"/Processos/Clientes/"+id).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
  public alterarProcessoCliente(processo:any): Observable<any> {
    return this.http.put<any>(marca+"/Marcas",processo).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
}
