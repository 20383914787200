import { Component, Input, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-resultados-importacao-processo',
  templateUrl: './resultados-importacao-processo.component.html',
  styleUrls: ['./resultados-importacao-processo.component.css']
})
export class ResultadosImportacaoProcessoComponent implements OnInit {

  @Input() resultadosImportacao :any= [];
  testando: any = [];
  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
     ;
    console.log(changes);
    //this.resultadosImportacao = changes.processo;
}

  // conferindo(){
  //    ;
  //   console.log(this.resultadosImportacao);
  // }
}
