import { DatePipe } from "@angular/common";

export const functionConst = {

    converteData(data:string):String
    {
        if(data !== "" && data != null)
        {
            let dataToString = new Date(data)
            if(dataToString.getFullYear()> 1)
            {
                var datePipe = new DatePipe('en-US');            
                return datePipe.transform(dataToString, 'yyyy-MM-dd');
            } 
        
        }
       
        return null
    },

    converteDataFormatoCalendario(data:string):String
    {
        if(data !== ""  )
        {
            let dataToString = new Date(data)
            if(dataToString.getFullYear()> 1)
            {
                var datePipe = new DatePipe('en-US');            
                return datePipe.transform(dataToString, 'dd/MM/yyyy');
            } 
        
        }
       
        return null
    }
  
}