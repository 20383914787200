import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { functionConst } from 'functionConst';

@Component({
  selector: 'app-quinquenio',
  templateUrl: './quinquenio.component.html',
  styleUrls: ['./quinquenio.component.css']
})
export class QuinquenioComponent implements OnChanges {

  constructor() { }

  @Output() anuidadesEvent = new EventEmitter();

  @Input() anuidades: Array<{
    // anuidade: "";
    // ordinarioPrazoInicio: "";
    // ordinarioPrazoFim: "";
    // extraordinarioPrazoInicio: "";
    // extraordinarioPrazoFim: "";
    // dataPagamento: "";
    // protocolo: "";
    // valor: "";
    dataPagamento: "";
    gru: "";
    idProcessoPatente: "";
    parcela: "";
    prazoExtraordinarioFim: "";
    prazoExtraordinarioInicio: "";
    prazoFim: "";
    prazoOrdinarioFim: "";
    prazoOrdinarioInicio: "";
    status: "";
    valor: "";
  }> = [];
  @Input() dataDeposito: string = "";
  @Input() novoProcesso:boolean = false;

  editando: boolean = false;
  indiceEdit: number = 0;

  @Input() processoExisteRpi: boolean = false;

  anuidadesForm: FormArray = new FormArray([]);

  anuidade: FormGroup = new FormGroup({
    // dataInicio:new FormControl(null),
    // dataFim:new FormControl(null),
    // anuidade: new FormControl(null),
    // ordinarioPrazoInicio: new FormControl(null),
    // ordinarioPrazoFim: new FormControl(null),
    // extraordinarioPrazoInicio: new FormControl(null),
    // extraordinarioPrazoFim: new FormControl(null),
    // dataPagamento: new FormControl(null),
    // protocolo: new FormControl(null),
    // valor: new FormControl(null),
    dataPagamento: new FormControl(null),
    gru: new FormControl(null),
    idProcessoPatente: new FormControl(null),
    parcela: new FormControl(null),
    prazoExtraordinarioFim: new FormControl(null),
    prazoExtraordinarioInicio: new FormControl(null),
    prazoFim: new FormControl(null),
    prazoOrdinarioFim: new FormControl(null),
    prazoOrdinarioInicio: new FormControl(null),
    status: new FormControl(null),
    valor: new FormControl(null)
  });

  prazoFimPrimeiro: string = "";

  ngOnChanges(changes: SimpleChanges): void {
     ;
    if (!changes.anuidades.firstChange) {
      for (var i = this.anuidades.length - 1; i >= 0; i--) {
        let an = this.anuidades[i];
        this.adicionaFormAnuidadade(
          an.dataPagamento,
          an.gru,
          an.idProcessoPatente,
          an.parcela,
          an.prazoExtraordinarioFim,
          an.prazoExtraordinarioInicio,
          an.prazoFim,
          an.prazoOrdinarioFim,
          an.prazoOrdinarioInicio,
          an.status,
          an.valor,

        );
        if(this.anuidades[i].prazoFim != null){
          this.prazoFimPrimeiro = an.prazoFim;
        }
      }
      this.eventAnuidades();
    }
  }

  eventAnuidades(){
    this.anuidadesEvent.emit(this.anuidadesForm);
  }

  editaAnuidade(indice: number) {
    this.editando = true;
    this.indiceEdit = indice;
    this.anuidade.setValue(this.anuidadesForm.value[indice]);
  }

  removerFormAnuidade(indice: number) {
    this.anuidadesForm.removeAt(indice);
  }

  converteDataAnuidade(data:string){
    return functionConst.converteDataFormatoCalendario(data);
  }

  limpaAnuidade() {
    this.editando = false;
    this.anuidade = new FormGroup({
      anuidade: new FormControl(null),
      ordinarioPrazoInicio: new FormControl(null),
      ordinarioPrazoFim: new FormControl(null),
      extraordinarioPrazoInicio: new FormControl(null),
      extraordinarioPrazoFim: new FormControl(null),
      dataPagamento: new FormControl(null),
      protocolo: new FormControl(null),
      valor: new FormControl(null),
    });
  }

  adicionarAnuidade() {
    if (!this.editando) this.anuidadesForm.push(this.anuidade);
    else {
      this.editando = false;
      console.log(this.anuidadesForm.controls[this.indiceEdit]);

      this.anuidadesForm.controls[this.indiceEdit].setValue(
        this.anuidade.value
      );
      this.indiceEdit = 0;
    }
    this.limpaAnuidade();
  }

  adicionaFormAnuidadade(
    dataPagamento,
    gru,
    idProcessoPatente,
    parcela,
    prazoExtraordinarioFim,
    prazoExtraordinarioInicio,
    prazoFim,
    prazoOrdinarioFim,
    prazoOrdinarioInicio,
    status,
    valor
  ) {
    if(parcela != "Vigência")
    {
    this.anuidadesForm.push(
      new FormGroup({
        dataPagamento: new FormControl(dataPagamento),
        gru: new FormControl(gru),
        idProcessoPatente: new FormControl(idProcessoPatente),
        parcela: new FormControl(parcela),
        prazoExtraordinarioFim: new FormControl(prazoExtraordinarioFim),
        prazoExtraordinarioInicio: new FormControl(prazoExtraordinarioInicio),
        prazoFim: new FormControl(prazoFim),
        prazoOrdinarioFim: new FormControl(prazoOrdinarioFim),
        prazoOrdinarioInicio: new FormControl(prazoOrdinarioInicio),
        status: new FormControl(status),
        valor: new FormControl(valor)
      })
    );
    }
  }
}
