import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { marcaClientes } from 'const';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MarcaClienteService {

  constructor(private http: HttpClient) { }

  public getProcessoClienteById(id): Observable<any> {
    // ;
    return this.http.get<any>(marcaClientes + "/" + id).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public searchProccess(queryParams): Observable<any> {
    return this.http.get<any>(`${marcaClientes}/BuscaProcesso/Filters?${queryParams}`).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public importProcessoByExcel(file: any, correspondente, cliente): Observable<any>{
    return this.http.post(`${marcaClientes}/Import/Excel/${cliente}?correspondente=${correspondente}`, file, { responseType: 'text' })
        .pipe(tap(), catchError(err => {
          return throwError(err);
        }));
    }



  public generateRelProccessClients(queryParams): Observable<any> {
    return this.http.get<any>(`${marcaClientes}/Excel?${queryParams}`, {responseType: 'blob' as 'json'})
  }

  public getProcessoByNumero(numero): Observable<any> {
    return this.http.get<any>(`${marcaClientes}/BuscaProcesso/Marcas/${numero}`).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
  
  public cadastrarProcessoCliente(processo:any): Observable<any> {
    return this.http.post<any>(`${marcaClientes}`, processo).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public alterarProcessoCliente(processo:any): Observable<any> {
    return this.http.put<any>(`${marcaClientes}`,processo).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
}
