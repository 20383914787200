import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-classificacao-marca',
  templateUrl: './classificacao-marca.component.html',
  styleUrls: ['./classificacao-marca.component.css']
})
export class ClassificacaoMarcaComponent implements OnInit {

  @Input() classes: any = {};
  @Input() processoExisteRpi: boolean = false;
  @Output() formClassMarca = new EventEmitter();

  editando:boolean = false;
  indiceEdit:number = 0;

  formClassificacao:FormGroup = new FormGroup({
    classe: new FormControl(null),
    especificacao: new FormControl(null),
    especificaoIngles: new FormControl(null),
    statusClasse: new FormControl(null),
  });
  
  classeFormArray: FormArray = new FormArray([]);

  constructor() { }

  ngOnInit(): void {
  }
  

  ngOnChanges(changes: SimpleChanges): void {
    debugger;
    try{
    if(!changes.classes.firstChange)
    {
      // this.formClassificacao.setValue(this.classes)
      // this.mudanca()
      this.classes.map((pet, i) => {
        this.adicionaClasseForm(pet.classe,pet.especificacao,pet.especificaoIngles,pet.statusClasse);
      });
    }   
  }catch(e){
    //  ;
    console.log('classificacao erro: ' + e)
  }        
  }

  mudanca(){
    this.formClassMarca.emit(this.classeFormArray);
  }

  adicionaClasseForm(
    classe,
    especificacao,
    especificaoIngles,
    statusClasse,
  ) {
    //  ;
    this.classeFormArray.push(
      new FormGroup({
        classe: new FormControl(classe),
        especificacao: new FormControl(especificacao),
        especificaoIngles: new FormControl(especificaoIngles),
        statusClasse: new FormControl(statusClasse),
      })
    );

    this.mudanca();
  }

  AdicionarProcesso()
  {
    if(!this.editando)
      this.classeFormArray.push(this.formClassificacao);
    else{
      this.editando = false;    
      this.classeFormArray.controls[this.indiceEdit].setValue(this.formClassificacao.value) 
      this.indiceEdit = 0;
    }

    this.mudanca();
  }

  limparProcesso()
  {
    this.editando = false;
    this.formClassificacao = new FormGroup({
      classe:new FormControl(null),
      especificacao: new FormControl(null),
      especificaoIngles:new FormControl(null),
      statusClasse:new FormControl(null),
    });

  }
}
