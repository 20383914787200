import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AuthGuard } from './auth.guard';
import { SistemaLayoutComponent } from './layouts/sistema-layout/sistema-layout.component';
import { AuthSistemaGuard } from './auth-sistema.guard';
import { LoginSistemaComponent } from './layouts/login-sistema/login-sistema.component';
import { LoginAdmComponent } from './layouts/login-adm/login-sistema.component';


const routes: Routes = [

  {
    path: 'login',
    component: LoginSistemaComponent
  },
  {
    path: 'loginAdm',
    component: LoginAdmComponent
  },
  {
    path: 'sistema',
    component: SistemaLayoutComponent,
    children: [{
      path: '',
      loadChildren: './layouts/sistema-layout/sistema-layout.module#SistemaLayoutModule',
      canActivate: [AuthSistemaGuard]
    }]
  },

  {
    path: '',
    component: AdminLayoutComponent,
    children: [{
      path: '',
      loadChildren: './layouts/admin-layout/admin-layout.module#AdminLayoutModule',
      canActivate: [AuthGuard]
    }]
  }

];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
      useHash: true
    })
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
