import { query } from '@angular/animations';
import { HttpParams } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MarcaProvider } from 'services/marca.provider';

@Component({
  selector: 'app-busca-marcas',
  templateUrl: './busca-marcas.component.html',
  styleUrls: ['./busca-marcas.component.css']
})
export class BuscaMarcasComponent implements OnInit {


  pag: Number = 1;
  contador: Number = 10;
  itemsPerPage: number = 10;
  
  busca: FormGroup = new FormGroup({
    processo: new FormControl(""),
    marca: new FormControl(""),
    titular: new FormControl(""),
    prioridadeUnionista: new FormControl(""),
    inscricaoInternacional: new FormControl(""),
    dataDeposito: new FormControl(""),
  });

  @Output() processo = new  EventEmitter();

  processos: any = [];
  mostraResultado:boolean = false;
  aguardaBusca:boolean = false;
  
  constructor(private service: MarcaProvider) { }

  ngOnInit(): void {
    // ;
  }

  validaForm(): boolean {
    if (
      this.busca.value.processo == "" &&
      this.busca.value.marca == "" &&
      this.busca.value.titular == "" &&
      this.busca.value.prioridadeUnionista == "" &&
      this.busca.value.inscricaoInternacional == "" &&
      this.busca.value.dataDeposito == ""
    ) {
      alert("É necessário digitar algum campo para efetuar a busca");
      return false;
    }

    return true;
  }

  mudaContador($event) {
    // ;
    this.contador = $event.target.value;
    setTimeout(() => {
      this.confereItems();
    }, 200);
  }

  confereItems() {
    // ;
    for (let i = 0; i < this.processos.length; i++) {
      let t = <HTMLInputElement>document.getElementById("seleciona_" + this.processos[i]);
      if (t != null) {
        t.checked = true;
      }
    }
  }

  novoProcesso()
  {
   this.processo.emit(null);
  }

  selecionaProcesso(processo:string){
    // ;
    this.processo.emit(processo);
  }

  limpar(){
    this.busca = new FormGroup({
      numero: new FormControl(""),
      marca: new FormControl(""),
      titular: new FormControl(""),
      prioridadeUnionista: new FormControl(""),
      inscricaoInternacional: new FormControl(""),
      dataDeposito: new FormControl(""),
    });
  }

  onSubmit() {
    if (this.validaForm()) {
      this.aguardaBusca = true;
      let queryParams = new HttpParams();

      queryParams = queryParams.append("processo", this.busca.value.processo)
        .append("marca", this.busca.value.marca).append("titular", this.busca.value.titular)
        .append("prioridadeUnionista", this.busca.value.prioridadeUnionista)
        .append("inscricaoInternacional", this.busca.value.inscricaoInternacional)
        .append("dataDeposito", this.busca.value.dataDeposito)
        .append("skip", "0")
        .append("take", "100");

        // ;
      this.service.GetProcessosByBusca({params: queryParams}).subscribe(
        (res) => {
          this.aguardaBusca = false;
          console.log(res);
          this.processos = res;
           ;
          this.mostraResultado = true;
        },
        (err) => {
          this.aguardaBusca = false;
          console.log(err);
        }
      );
    }
  }

}
