import { Observable, throwError, observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { catchError, retry, take, map } from 'rxjs/operators';
import { cliente, colidencia, colidenciaFigurativa } from '../const';
import { Injectable } from '@angular/core';
import { Clientes } from 'models/Clientes';

@Injectable()
export class colidenciaProvider {
   constructor(private http: HttpClient) { }



   sairLeitura(obj: any): Observable<any> {
      return this.http.post<any>(colidencia + "/selecaoVoto/leitura/sair", obj).pipe(
         map(res => {
            return res;
         }), catchError(err => {
            return throwError(err);
         })
      )
   }

   finalizarLeitura(obj: any): Observable<any> {
      return this.http.post<any>(colidencia + "/selecaoVoto/leitura/finalizar", obj).pipe(
         map(res => {
            return res;
         }), catchError(err => {
            return throwError(err);
         })
      )
   }

   excluirRpi(obj: any): Observable<any> {
      return this.http.post<any>(colidencia+"/del", obj).pipe(
         map(res => {
            return res;
         }), catchError(err => {
            return throwError(err);
         })
      )
   }


   votarColidencia(obj: any): Observable<any> {
      return this.http.post<any>(colidencia + "/selecaoVoto/voto", obj).pipe(
         map(res => {
            return res;
         }), catchError(err => {
            return throwError(err);
         })
      )
   }

   votarColidenciaFigurativa(obj: any): Observable<any> {
      return this.http.post<any>(colidenciaFigurativa + "selecaoVoto/voto", obj).pipe(
         map(res => {
            return res;
         }), catchError(err => {
            return throwError(err);
         })
      )
   }

   getProcessosComparacaoVoto(processo: number): Observable<any> {
      return this.http.get<any>(colidencia + "/selecaoVoto/processoComparacao/" + processo).pipe(
         map(res => {
            return res;
         }), catchError(err => {
            return throwError(err);
         })
      )
   }

   getProcessosComparacaoVotoFigurativa(processo: number): Observable<any> {
      return this.http.get<any>(colidenciaFigurativa + "selecaoVoto/processoComparacao/" + processo).pipe(
         map(res => {
            return res;
         }), catchError(err => {
            return throwError(err);
         })
      )
   }

   getRpisFinalizadasSelecaoVoto(idCliente): Observable<any> {
      return this.http.get<any>(colidencia + "/selecaoVoto/rpis/" + idCliente).pipe(
         map(res => {
            return res;
         }), catchError(err => {
            return throwError(err);
         })
      )
   }

   getRpisFinalizadasSelecaoVotoFigurativo(idCliente): Observable<any> {
      return this.http.get<any>(colidenciaFigurativa + "selecaoVoto/rpis/" + idCliente).pipe(
         map(res => {
            return res;
         }), catchError(err => {
            return throwError(err);
         })
      )
   }

   getQtdVotoRpi(idCliente): Observable<any> {
      return this.http.get<any>(colidencia + "/selecaoVoto/qtdVotos/" + idCliente).pipe(
         map(res => {
            return res;
         }), catchError(err => {
            return throwError(err);
         })
      )
   }

   getDespachos(): Observable<any> {
      return this.http.get<any>(colidencia + "/colidencia").pipe(
         map(res => {
            return res;
         }), catchError(err => {
            return throwError(err);
         })
      )
   }

   getFiltrosVotos(idCliente,rpi): Observable<any> {
      return this.http.get<any>(colidencia + "/filtros/"+idCliente+"/"+rpi).pipe(
         map(res => {
            return res;
         }), catchError(err => {
            return throwError(err);
         })
      )
   }

   getFiltrosVotosFigurativo(idCliente,rpi): Observable<any> {
      return this.http.get<any>(colidenciaFigurativa + "filtros/"+idCliente+"/"+rpi).pipe(
         map(res => {
            return res;
         }), catchError(err => {
            return throwError(err);
         })
      )
   }

   getFiltrosColidencia(idCliente,rpi): Observable<any> {
      return this.http.get<any>(colidencia + "/filtrosColidencia/"+idCliente+"/"+rpi).pipe(
         map(res => {
            return res;
         }), catchError(err => {
            return throwError(err);
         })
      )
   }

   getFiltrosColidenciaFigurativa(idCliente,rpi): Observable<any> {
      return this.http.get<any>(colidenciaFigurativa + "filtrosColidencia/"+idCliente+"/"+rpi).pipe(
         map(res => {
            return res;
         }), catchError(err => {
            return throwError(err);
         })
      )
   }

   getColidenciasVotoRpi(obj): Observable<any> {
      return this.http.post<any>(colidencia + "/selecaoVoto/colidencias/", obj).pipe(
         map(res => {
            return res;
         }), catchError(err => {
            return throwError(err);
         })
      )
   }

   getColidenciasVotoRpiFigurativa(obj): Observable<any> {
      return this.http.post<any>(colidenciaFigurativa + "selecaoVoto/colidencias/", obj).pipe(
         map(res => {
            return res;
         }), catchError(err => {
            return throwError(err);
         })
      )
   }

   getRpis(idCliente): Observable<any> {
      return this.http.get<any>(colidencia + "/rpi/" + idCliente).pipe(
         map(res => {
            return res;
         }), catchError(err => {
            return throwError(err);
         })
      )
   }

   getRpisFigurativa(idCliente): Observable<any> {
      return this.http.get<any>(colidenciaFigurativa + idCliente).pipe(
         map(res => {
            return res;
         }), catchError(err => {
            return throwError(err);
         })
      )
   }

   getVolumes(idCliente, rpi: number, idUsuario): Observable<any> {
      return this.http.get<any>(colidencia + "/rpi/" + idCliente + "/" + rpi + "/" + idUsuario).pipe(
         map(res => {
            return res;
         }), catchError(err => {
            return throwError(err);
         })
      )
   }

   getVolumesFigurativa(idCliente, rpi: number, idUsuario): Observable<any> {
      return this.http.get<any>(colidenciaFigurativa + "rpi/" + idCliente + "/" + rpi + "/" + idUsuario).pipe(
         map(res => {
            return res;
         }), catchError(err => {
            return throwError(err);
         })
      )
   }

   getFinalizarRpi(idCliente, rpi: number, idUsuario: string): Observable<any> {
      return this.http.get<any>(colidencia + "/volume/" + idCliente + "/" + rpi + "/" + idUsuario).pipe(
         map(res => {
            return res;
         }), catchError(err => {
            return throwError(err);
         })
      )
   }

   getFinalizarRpiFigurativa(idCliente, rpi: number, idUsuario: string): Observable<any> {
      return this.http.get<any>(colidenciaFigurativa + "volume/" + idCliente + "/" + rpi + "/" + idUsuario).pipe(
         map(res => {
            return res;
         }), catchError(err => {
            return throwError(err);
         })
      )
   }




   getColidencias(obj: any): Observable<any> {
      return this.http.post<any>(colidencia, obj).pipe(
         map(res => {
            return res;
         }), catchError(err => {
            return throwError(err);
         })
      )
   }

   getColidenciasFigurativa(obj: any): Observable<any> {
      return this.http.post<any>(colidenciaFigurativa, obj).pipe(
         map(res => {
            return res;
         }), catchError(err => {
            return throwError(err);
         })
      )
   }

   getQuantidade(obj: any): Observable<any> {
      return this.http.post<any>(colidencia + "/quantidade", obj).pipe(
         map(res => {
            return res;
         }), catchError(err => {
            return throwError(err);
         })
      )
   }

   setFinalizarRpi(obj: any): Observable<any> {
      return this.http.post<any>(colidencia + "/finaliza", obj).pipe(
         map(res => {
            return res;
         }), catchError(err => {
            return throwError(err);
         })
      )
   }

   setFinalizarRpiFigurativa(obj: any): Observable<any> {
      return this.http.post<any>(colidenciaFigurativa + "finaliza", obj).pipe(
         map(res => {
            return res;
         }), catchError(err => {
            return throwError(err);
         })
      )
   }

   getProcessosComparacao(processo): Observable<any> {
      return this.http.get<any>(colidencia + "/processoComparacao/" + processo).pipe(
         map(res => {
            return res;
         }), catchError(err => {
            return throwError(err);
         })
      )
   }

   getProcessosComparacaoFigurativa(processo): Observable<any> {
      return this.http.get<any>(colidenciaFigurativa + "processoComparacao/" + processo).pipe(
         map(res => {
            return res;
         }), catchError(err => {
            return throwError(err);
         })
      )
   }


   setCheckColidencia(obj: any): Observable<any> {
      return this.http.post<any>(colidencia + "/marcaColidencia", obj).pipe(
         map(res => {
            return res;
         }), catchError(err => {
            return throwError(err);
         })
      )
   }

   setCheckColidenciaFigurativa(obj: any): Observable<any> {
      return this.http.post<any>(colidenciaFigurativa + "marcaColidencia", obj).pipe(
         map(res => {
            return res;
         }), catchError(err => {
            return throwError(err);
         })
      )
   }

   finalizarColidencia(obj: any): Observable<any> {
      return this.http.post<any>(colidencia + "/volume", obj).pipe(
         map(res => {
            return res;
         }), catchError(err => {
            return throwError(err);
         })
      )
   }

   finalizarColidenciaFigurativa(obj: any): Observable<any> {
      return this.http.post<any>(colidenciaFigurativa + "volume", obj).pipe(
         map(res => {
            return res;
         }), catchError(err => {
            return throwError(err);
         })
      )
   }

   buscaProcessosClientes(obj: any): Observable<any> {
      return this.http.post<any>(colidencia + "/Processo", obj).pipe(
         map(res => {
            return res;
         }), catchError(err => {
            return throwError(err);
         })
      )
   }

   adicionarProcessoColidencia(obj: any): Observable<any> {
      return this.http.post<any>(colidencia + "/AddProcesso", obj).pipe(
         map(res => {
            return res;
         }), catchError(err => {
            return throwError(err);
         })
      )
   }

   adicionarProcessoColidenciaVoto(obj: any): Observable<any> {
      return this.http.post<any>(colidencia + "/selecaoVoto/AddProcesso", obj).pipe(
         map(res => {
            return res;
         }), catchError(err => {
            return throwError(err);
         })
      )
   }

   sairDoVolume(obj: any): Observable<any> {
      return this.http.put<any>(colidencia + "/volume", obj).pipe(
         map(res => {
            return res;
         }), catchError(err => {
            return throwError(err);
         })
      )
   }

   sairDoVolumeColidencia(obj: any): Observable<any> {
      return this.http.put<any>(colidencia + "/volume", obj).pipe(
         map(res => {
            return res;
         }), catchError(err => {
            return throwError(err);
         })
      )
   }

   sairDoVolumeColidenciaFigurativa(obj: any): Observable<any> {
      return this.http.put<any>(colidenciaFigurativa + "volume", obj).pipe(
         map(res => {
            return res;
         }), catchError(err => {
            return throwError(err);
         })
      )
   }
}