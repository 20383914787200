import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ID_CLIENTE } from 'app/constantes/variaveis';
import { patenteProvider } from 'services/patente.provider';

@Component({
  selector: 'app-processo-desenhos',
  templateUrl: './processo-desenhos.component.html',
  styleUrls: ['./processo-desenhos.component.css']
})
export class ProcessoDesenhosComponent implements OnInit {
  processoExisteRpi: boolean = false;
  constructor(
    private service: patenteProvider,
    private route: ActivatedRoute
  ) { }


  @Input() recebeTipo = new EventEmitter();
  processoDesenhoClienteForm: FormGroup = new FormGroup({
    id: new FormControl(null),
    existeRpi: new FormControl(false),
    idCliente: new FormControl(ID_CLIENTE),
    detalhes: new FormGroup({}),
    datasProcesso: new FormGroup({}),
    prioridadeUnionista: new FormArray([]),
    //pct: new FormGroup({}),
    partesProcesso: new FormGroup({}),
    anuidade: new FormArray([]),
    peticoes: new FormArray([]),
    publicacoes: new FormArray([]),
    agendaPrazosPatentes: new FormArray([]),
    processosVinculados: new FormArray([]),
    historicoProcessos: new FormArray([]),
    dadosGerais: new FormGroup({}),
  });

  novoProcesso = true;
  showBusca = true;
  idCliente = ID_CLIENTE;
  numeroProcesso = null;
  processoDetalhes: any = {};
  datas: any = {};
  prioridade: any = {};
  pct: any = {};
  partesProcesso: any = {};
  quinquenio: any = {};
  peticao: any = {};
  publicacao: any = {};
  processosVinculados:any = {};
  historicoProc:any = [];
  dadosGerais:any = {};
  agendaPrazosPatentes:any = [];
  dataDepositoQuinquenio: any = {};

  ngOnInit(): void {
     ;
    this.route.queryParamMap.subscribe(
      (res) => {
        if (res.get("processo") != null) {
           ;
          this.showBusca = false;
          if(res.get("tipo") == 'desenho industrial'){
            this.service
              .getProcessoDesenhoClienteById(res.get("processo"))
              .subscribe((res) => {
                this.AtribuindoValoresProcesso(res);
                console.log(res);
              });

          }
        }
      },
      (err) => {}
    );
  }

  recebeDetalhesProcesso(proc: FormGroup) {
    this.processoDesenhoClienteForm.controls["detalhes"] = proc;
  }

  recebeDatasProcesso(datas: FormGroup) {
    this.processoDesenhoClienteForm.controls["datasProcesso"] = datas;
  }

  recebePrioridadeUnionista(prioridades: FormArray) {
    this.processoDesenhoClienteForm.controls["prioridadeUnionista"] =
      prioridades;
  }

  // recebePct(pct: FormGroup) {
  //   this.processoDesenhoClienteForm.controls["pct"] = pct;
  // }

  recebePartesProcesso(partes: FormGroup) {
    this.processoDesenhoClienteForm.controls["partesProcesso"] = partes;    
  }

  recebeQuinquenio(quinquenio:FormArray){
    this.processoDesenhoClienteForm.controls['anuidade'] = quinquenio;
  };

  recebePeticoes(peticoes: FormArray) {
    this.processoDesenhoClienteForm.controls["peticoes"] = peticoes;
  }

  recebeProcessoVinculados(processosVinculado: FormArray) {
    this.processoDesenhoClienteForm.controls["processosVinculados"] =
      processosVinculado;
  }

  recebeHistoricoProcesso(historicoProcessos: FormArray) {
    this.processoDesenhoClienteForm.controls["historicoProcessos"] =
      historicoProcessos;
  }

  recebeDadosGeraisProcesso(dadosGerais: FormGroup) {
    this.processoDesenhoClienteForm.controls["dadosGerais"] = dadosGerais;
  }


  AtribuindoValoresProcesso(res) {
     ;
    this.processoDesenhoClienteForm.controls["id"].setValue(res.id);
    this.processoDesenhoClienteForm.controls["existeRpi"].setValue(
      res.existeRpi
    );
    this.processoDesenhoClienteForm.controls["idCliente"].setValue(
      res.idCliente
    );
    this.dadosGerais = res.dadosGerais;    
    this.processoExisteRpi = res.existeRpi;
    this.novoProcesso = false;
    this.processoDetalhes = res.detalhes;
    this.numeroProcesso = this.processoDetalhes.processo;
    this.datas = res.datasProcesso;
    this.prioridade = res.prioridadeUnionista;
    //this.pct = res.pct;
    this.partesProcesso = res.partesProcesso;
    this.quinquenio = res.anuidades;
    this.peticao = res.peticoes;
    this.publicacao = res.publicacoes;
    this.processosVinculados = res.processosVinculados;
    this.historicoProc = res.historicoProcessos;  
    this.agendaPrazosPatentes = res.agendaPrazosProcesso;  
    this.dataDepositoQuinquenio = res.datasProcesso.dataDeposito;
  }

  RecebeProcessoByRpi(proc: any) {
    this.showBusca = false;
    if (proc != null) {
      this.service.getProcessoByNumero(proc).subscribe(
        (res) => {
          console.log(res);
          this.processoExisteRpi = true;
          this.novoProcesso = false;          
          this.processoDesenhoClienteForm.controls["existeRpi"].setValue(true);
          this.idCliente = res.idCliente,
          this.processoExisteRpi = true;
          this.processoDetalhes = res.detalhes;
          this.datas = res.datasProcesso;
          this.prioridade = res.prioridadeUnionista;
          this.pct = res.pct;
          this.partesProcesso = res.partesProcesso;
          this.quinquenio = res.quinquenio;
          this.peticao = res.peticoes;
          this.publicacao = res.publicacoes;
          this.agendaPrazosPatentes = res.agendaPrazosProcesso;  
    
        },
        (err) => {}
      );
    } else {
      this.NovoProcesso();
    }
  }

  NovoProcesso() {
    this.showBusca = false;
    this.processoDesenhoClienteForm.controls["existeRpi"].setValue(false);
    this.service.getNumeroNovoProcessoByCliente(ID_CLIENTE).subscribe(
      (res) => {
        this.processoExisteRpi = false;
        this.novoProcesso = true;
        //Definindo o novo número do processo
        this.processoDetalhes = {
          processo: res.processo,
          processoAnterior: "",
          processoDividido: "",
          classificacaoIpc: "",
          classificacaoCpc: "",
          titulo: "",
          resumo: "",
          situacaoProcesso: "",
        };
      },
      (err) => {
        console.log(err);
      }
    );
  }

  salvarProcesso() {
    let objEnvio = {
      id: this.processoDesenhoClienteForm.controls["id"].value,
      existeRpi: this.processoDesenhoClienteForm.controls["existeRpi"].value,
      idCliente: this.processoDesenhoClienteForm.controls["idCliente"].value,
      detalhes: this.processoDesenhoClienteForm.controls["detalhes"].value,
      datasProcesso:
        this.processoDesenhoClienteForm.controls["datasProcesso"].value,
      prioridadeUnionista:
        this.processoDesenhoClienteForm.controls["prioridadeUnionista"].value,
      // pct: this.processoDesenhoClienteForm.controls["pct"].value,
      partesProcesso:
        this.processoDesenhoClienteForm.controls["partesProcesso"].value,
      quinquenio: this.processoDesenhoClienteForm.controls["anuidades"].value,
      peticoes: this.processoDesenhoClienteForm.controls["peticoes"].value,
      publicacoes:
        this.processoDesenhoClienteForm.controls["publicacoes"].value,
      processosVinculados:
        this.processoDesenhoClienteForm.controls["processosVinculados"].value,
      historicoProcessos:
        this.processoDesenhoClienteForm.controls["historicoProcessos"].value,
      dadosGerais:
        this.processoDesenhoClienteForm.controls["dadosGerais"].value,
    };
    console.log(objEnvio);

    //Verificando ID para alterar o objeto
    if (objEnvio.id != null) {
      this.service.alterarProcessoCliente(objEnvio).subscribe(
        (res) => {
          alert(res.messageText);
        },
        (err) => {
          alert("Ocorreu um erro ao cadastrar o processo");
        }
      );
    } else {
      this.service.cadastrarProcessoCliente(objEnvio).subscribe(
        (res) => {
          alert(res.messageText);
        },
        (err) => {
          alert("Ocorreu um erro ao cadastrar o processo");
        }
      );
    }
  }
}
