import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { RouterModule } from '@angular/router';
import { MatFormFieldModule } from '@angular/material/form-field';

import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';

import { AppComponent } from './app.component';

import {
  AgmCoreModule
} from '@agm/core';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { clienteProvider } from '../services/cliente.provider';
import { HttpClientModule } from '@angular/common/http';
import { correiosProvider } from 'services/correios.provider';
import { AuthGuard } from './auth.guard';
import { SistemaLayoutComponent } from './layouts/sistema-layout/sistema-layout.component';
import { AuthSistemaGuard } from './auth-sistema.guard';
import { LoginSistemaComponent } from './layouts/login-sistema/login-sistema.component';
import { SistemaLayoutModule } from './layouts/sistema-layout/sistema-layout.module';
import { loginProvider } from 'services/login.provider';
import { MatInputModule } from '@angular/material/input';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { LoginAdmComponent } from './layouts/login-adm/login-sistema.component';
import { EmpresaProvider } from 'services/empresa.provider';
import { ParametrosPrazosProvider } from 'services/parametros-prazos.provider';
import { ParametrosCadastraisProvider } from 'services/parametrosCadastrais.provider';
import { NgxPaginationModule } from 'ngx-pagination';
import { SelecaoColidenciaMarcaComponent } from './sistema/marcas/colidencias/selecao-colidencia-marca/selecao-colidencia-marca.component';
import { ColidenciaComponent } from './admin/colidencia/colidencia.component';
import { DataTablesModule } from 'angular-datatables';
import { guiaRecolhimentoProvider } from 'services/guiaRecolhimento.provider';
import { MatDialogModule } from '@angular/material/dialog';
import { usuarioProvider } from 'services/usuario.provider';
import { RelatorioMarcasComponent } from './sistema/marcas/colidencias/relatorio/relatorio-marcas/relatorio-marcas.component';
import { CartasComponent } from './sistema/marcas/colidencias/cartas/cartas.component';
import { ExcluirColidenciaComponent } from './admin/excluir-colidencia/excluir-colidencia.component';
import { ParametrosGeraisComponent } from './admin/parametros/parametros-gerais/parametros-gerais.component';
import { ParametrosMarcasComponent } from './admin/parametros/parametros-marcas/parametros-marcas.component';
import { ParametrosPatentesComponent } from './admin/parametros/parametros-patentes/parametros-patentes.component';
import { ParametrosDesenhoIndustrialComponent } from './admin/parametros/parametros-desenho-industrial/parametros-desenho-industrial.component';
import { ParametrosJuridicoComponent } from './admin/parametros/parametros-juridico/parametros-juridico.component';
import { ParametrosDominioComponent } from './admin/parametros/parametros-dominio/parametros-dominio.component';
import { ParametrosSoftwareComponent } from './admin/parametros/parametros-software/parametros-software.component';
import { ParametrosContratosComponent } from './admin/parametros/parametros-contratos/parametros-contratos.component';
import { ParametrosIndicacaoGeograficaComponent } from './admin/parametros/parametros-indicacao-geografica/parametros-indicacao-geografica.component';
import { SelecaoColidenciaMarcaFigurativaComponent } from './sistema/marcas/colidencias/selecao-colidencia-marca-figurativa/selecao-colidencia-marca-figurativa.component';
import { RespotaPrincipalComponent } from './sistema/marcas/colidencias/selecao-colidencia-marca-figurativa/respota-principal/respota-principal.component';
import { RespotaSecundariaComponent } from './sistema/marcas/colidencias/selecao-colidencia-marca-figurativa/respota-secundaria/respota-secundaria.component';
import { CommonModule } from '@angular/common';
import { ProcessoPatentesComponent } from './sistema/patentes/processo-patentes/processo-patentes.component';
import { DetalhesProcessoComponent } from './sistema/patentes/processo-patentes/detalhes-processo/detalhes-processo.component';
import { DatasProcessoComponent } from './sistema/patentes/processo-patentes/datas-processo/datas-processo.component';
import { PrioridadeUniostaComponent } from './sistema/patentes/processo-patentes/prioridade-uniosta/prioridade-uniosta.component';
import { PctComponent } from './sistema/patentes/processo-patentes/pct/pct.component';
import { PartesProcessoComponent } from './sistema/patentes/processo-patentes/partes-processo/partes-processo.component';
import { AnuidadesComponent } from './sistema/patentes/processo-patentes/anuidades/anuidades.component';
import { PeticoesComponent } from './sistema/patentes/processo-patentes/peticoes/peticoes.component';
import { PublicacoesComponent } from './sistema/patentes/processo-patentes/publicacoes/publicacoes.component';
import { ProcessosVinculadosComponent } from './sistema/patentes/processo-patentes/processos-vinculados/processos-vinculados.component';
import { AgendasPrazoComponent } from './sistema/patentes/processo-patentes/agendas-prazo/agendas-prazo.component';
import { HistoricoProcessoComponent } from './sistema/patentes/processo-patentes/historico-processo/historico-processo.component';
import { DadosGeraisProcessoComponent } from './sistema/patentes/processo-patentes/dados-gerais-processo/dados-gerais-processo.component';
import { BuscaProcessoComponent } from './sistema/patentes/busca-processo/busca-processo.component';
import { BuscaProcessClientesComponent } from './sistema/patentes/busca-process-clientes/busca-process-clientes.component';
import { AgendaPrazosPatentesComponent } from './sistema/patentes/agenda-prazos-patentes/agenda-prazos-patentes.component';
import { FiltroPrazosComponent } from './sistema/patentes/agenda-prazos-patentes/filtro-prazos/filtro-prazos.component';
import { CadastroPrazosComponent } from './sistema/patentes/agenda-prazos-patentes/cadastro-prazos/cadastro-prazos.component';
import { ResultadoBuscaComponent } from './sistema/patentes/agenda-prazos-patentes/resultado-busca/resultado-busca.component';
import { ImportacaoPatenteComponent } from './sistema/controle/importacao-patente/importacao-patente.component';
import { ResultadoAnuidadesComponent } from './sistema/patentes/agenda-anuidades-patentes/resultado-anuidades/resultado-anuidades.component';
import { EditaAnuidadeComponent } from './sistema/patentes/agenda-anuidades-patentes/edita-anuidade/edita-anuidade.component';
import { FiltroAnuidadesComponent } from './sistema/patentes/agenda-anuidades-patentes/filtro-anuidades/filtro-anuidades.component';
import { PesquisaProcessoRpiComponent } from './sistema/controle/pesquisa-processo-rpi/pesquisa-processo-rpi.component';
import { ResultadosImportacaoProcessoComponent } from './sistema/controle/importacao-patente/resultados-importacao-processo/resultados-importacao-processo.component';
import { ResultadosPesquisaComponent } from './sistema/controle/pesquisa-processo-rpi/resultados-pesquisa/resultados-pesquisa.component';
import { CobrancaClienteComponent } from './admin/cobranca-cliente/cobranca-cliente.component';
import { ResultadosCobrancaComponent } from './admin/cobranca-cliente/resultados-cobranca/resultados-cobranca.component';
import { ProcessoDesenhosComponent } from './sistema/patentes/processo-desenhos/processo-desenhos.component';
import { DetalhesProcessoDesenhoComponent } from './sistema/patentes/processo-desenhos/detalhes-processo-desenho/detalhes-processo-desenho.component';
import { DatasProcessoDesenhoComponent } from './sistema/patentes/processo-desenhos/datas-processo-desenho/datas-processo-desenho.component';
import { PrioridadeUnionistaDesenhoComponent } from './sistema/patentes/processo-desenhos/prioridade-unionista-desenho/prioridade-unionista-desenho.component';
import { PartesProcessoDesenhoComponent } from './sistema/patentes/processo-desenhos/partes-processo-desenho/partes-processo-desenho.component';
import { QuinquenioComponent } from './sistema/patentes/processo-desenhos/quinquenio/quinquenio.component';
import { PeticoesDesenhoComponent } from './sistema/patentes/processo-desenhos/peticoes-desenho/peticoes-desenho.component';
import { PublicacoesDesenhoComponent } from './sistema/patentes/processo-desenhos/publicacoes-desenho/publicacoes-desenho.component';
import { ProcessosVinculadosDesenhoComponent } from './sistema/patentes/processo-desenhos/processos-vinculados-desenho/processos-vinculados-desenho.component';
import { AgendaPrazoDesenhoComponent } from './sistema/patentes/processo-desenhos/agenda-prazo-desenho/agenda-prazo-desenho.component';
import { HistoricoProcessoDesenhoComponent } from './sistema/patentes/processo-desenhos/historico-processo-desenho/historico-processo-desenho.component';
import { DadosGeraisProcessoDesenhoComponent } from './sistema/patentes/processo-desenhos/dados-gerais-processo-desenho/dados-gerais-processo-desenho.component';
import { BuscaMarcasComponent } from './sistema/marcas/busca-marcas/busca-marcas.component';
import { ProcessoMarcasComponent } from './sistema/marcas/processo-marcas/processo-marcas.component';
import { DetalhesMarcaComponent } from './sistema/marcas/processo-marcas/detalhes-marca/detalhes-marca.component';
import { DatasMarcaComponent } from './sistema/marcas/processo-marcas/datas-marca/datas-marca.component';
import { ClassificacaoMarcaComponent } from './sistema/marcas/processo-marcas/classificacao-marca/classificacao-marca.component';
import { PrioridadeMarcaComponent } from './sistema/marcas/processo-marcas/prioridade-marca/prioridade-marca.component';
import { DesignacaoMarcaComponent } from './sistema/marcas/processo-marcas/designacao-marca/designacao-marca.component';
import { PartesMarcaComponent } from './sistema/marcas/processo-marcas/partes-marca/partes-marca.component';
import { AgendaMarcaComponent } from './sistema/marcas/processo-marcas/agenda-marca/agenda-marca.component';
import { PeticoesMarcaComponent } from './sistema/marcas/processo-marcas/peticoes-marca/peticoes-marca.component';
import { PublicacoesMarcaComponent } from './sistema/marcas/processo-marcas/publicacoes-marca/publicacoes-marca.component';
import { ProcessosVinculadosMarcaComponent } from './sistema/marcas/processo-marcas/processos-vinculados-marca/processos-vinculados-marca.component';
import { HistoricoMarcaComponent } from './sistema/marcas/processo-marcas/historico-marca/historico-marca.component';
import { DadosGeraisMarcaComponent } from './sistema/marcas/processo-marcas/dados-gerais-marca/dados-gerais-marca.component';
import { CheckStringPipePipe } from './pipes/check-string-pipe.pipe';
import { ClassesProcessoMarcasPipe } from './pipes/classes-processo-marcas.pipe';
import { ImportacaoMarcasComponent } from './sistema/controle/importacao-marcas/importacao-marcas.component';
import { ResultadoImportacaoMarcasComponent } from './sistema/controle/importacao-marcas/resultado-importacao-marcas/resultado-importacao-marcas.component';
import { pesquisaNomeEmpresarialProvider } from 'services/pesquisaNomeEmpresarial.provider';
import { TagInputModule } from 'ngx-chips';
import { pesquisaNomeDominioProvider } from 'services/pesquisaNomeDominio.provider';

@NgModule({
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ComponentsModule,
    RouterModule,
    AppRoutingModule,
    MatFormFieldModule,
    MatInputModule,
    Ng2SearchPipeModule,
    NgxPaginationModule,
    DataTablesModule,
    MatDialogModule,
    CommonModule,
    TagInputModule
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    SistemaLayoutComponent,
    LoginSistemaComponent,
    LoginAdmComponent,
    ExcluirColidenciaComponent,
    AgendaPrazosPatentesComponent,
    FiltroPrazosComponent,
    CadastroPrazosComponent,
    ResultadoBuscaComponent,
    ImportacaoPatenteComponent,
    PesquisaProcessoRpiComponent,
    ResultadosImportacaoProcessoComponent,
    ResultadosPesquisaComponent,
    CobrancaClienteComponent,
    ResultadosCobrancaComponent,
    ProcessoDesenhosComponent,
    DetalhesProcessoDesenhoComponent,
    DatasProcessoDesenhoComponent,
    PrioridadeUnionistaDesenhoComponent,
    PartesProcessoDesenhoComponent,
    QuinquenioComponent,
    PeticoesDesenhoComponent,
    PublicacoesDesenhoComponent,
    ProcessosVinculadosDesenhoComponent,
    AgendaPrazoDesenhoComponent,
    HistoricoProcessoDesenhoComponent,
    DadosGeraisProcessoDesenhoComponent,
    ProcessoMarcasComponent,
    DetalhesMarcaComponent,
    DatasMarcaComponent,
    ClassificacaoMarcaComponent,
    PrioridadeMarcaComponent,
    DesignacaoMarcaComponent,
    PartesMarcaComponent,
    AgendaMarcaComponent,
    PeticoesMarcaComponent,
    PublicacoesMarcaComponent,
    ProcessosVinculadosMarcaComponent,
    HistoricoMarcaComponent,
    DadosGeraisMarcaComponent,
    BuscaMarcasComponent
  ],
  providers: [clienteProvider, correiosProvider, loginProvider, AuthGuard, AuthGuard, AuthSistemaGuard, EmpresaProvider, ParametrosPrazosProvider,
    ParametrosCadastraisProvider, guiaRecolhimentoProvider, usuarioProvider, pesquisaNomeEmpresarialProvider, pesquisaNomeDominioProvider],
  bootstrap: [AppComponent]
})
export class AppModule { }
